import React from "react"

/* 
 * Component which serves the purpose of a "root route component". 
 */
class Page extends React.Component {
  /**
   * Here, we define a react lifecycle method that gets executed each time 
   * our component is mounted to the DOM, which is exactly what we want in this case
   */
  componentDidMount() {
    const baseTitle = "Po-Shen Loh";
    if (this.props.title === "") {
      document.title = baseTitle;
    } else {
      document.title = baseTitle + " | " + this.props.title;
    }
  }

  /**
   * Here, we use a component prop to render 
   * a component, as specified in route configuration
   */
  render() {
    const PageComponent = this.props.component;

    return (
      <PageComponent />
    )
  }
}

export default Page;