import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import {PostJumbotron} from './../components/PostJumbotron';
import {Footer} from './../components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}

.viete {
  color: #009046;
}

.babylon {
  color: #0169a9;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
`;

const Video = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25;
    height: 0;
    margin-bottom: 30px;
`;

const ULStyled = styled.ul`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

export const QuadraticDetail = () => {
  return (
    <Styles>
    <PostJumbotron 
    date = 'Posted Oct 13, 2019; last updated Aug 6, 2021'
    title = 'Quadratic Method: Detailed Explanation'
    subTitle = ''>
    </PostJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
    I've recently been systematically thinking about how to explain school math
    concepts in more thoughtful and interesting ways, while creating my <i><a
    href="https://daily.poshenloh.com">Daily Challenge</a></i> lessons. One
    night in September 2019, while brainstorming different ways to think about
    the quadratic formula, I was surprised to come up with a simple method of
    eliminating guess-and-check from factoring that I had never seen before.
    </PStyled>
    
    <h2 id="method">Alternative Method of Solving Quadratic Equations</h2>
    <OLStyled>

    <li className="viete">If you find $r$ and $s$ with sum $-B$ and product $C$, then $x^2 + Bx +
    C = (x-r)(x-s)$, and they are all the roots</li>

    <li className="babylon">Two numbers sum to $-B$ when they are {`$-\\frac{B}{2} \\pm u$`}</li>

    <li className="babylon">Their product is $C$ when {`$\\frac{B^2}{4} - u^2 = C$`}</li>
    
    <li className="babylon">Square root always gives valid $u$</li>

    <li>Thus {`$-\\frac{B}{2} \\pm u$`} work as $r$ and $s$, and are all the
    roots</li>

    </OLStyled>

    <PStyled>
    <i className="viete">Known hundreds of years ago (Vi&egrave;te)</i>
    <br />
    <i className="babylon">Known thousands of years ago (Babylonians, Greeks)</i>
    </PStyled>

    <PStyled>
    The individual steps of this method had been separately discovered by
    ancient mathematicians. The combination of these steps is something that
    anyone could have come up with, but after releasing this webpage to the
    wild, the only previous reference that surfaced, of a similar coherent
    method for solving quadratic equations, was a nice <a
    href="https://www.jstor.org/stable/27966090">article by mathematics teacher
    John Savage</a>, published in <i>The Mathematics Teacher</i> in 1989. His
    approach overlapped in almost all calculations, with a pedagogical
    difference in choice of sign, but had a difference in logic, as (possibly
      due to a friendly writing style which leaves some logic up for
      interpretation) it appears to use the additional (true but significantly
        more advanced) fact that every quadratic can be factored into two linear
    factors, or has some reversed directions of implication that are not
    technically correct. In particular, my approach's avoidance of an <a
    href="/quadraticcompsqr">extra assumption in Completing the Square</a> was
    not achieved by Savage's method. The <a href="/quadraticrelated">related
    work</a> page compares the method described by Savage, with the method that
    I proposed. Since I still have not seen any previously-existing book or
    paper which states this type of method in a way that is suitable for
    first-time learners (avoiding advanced knowledge) and justifies all steps
    clearly and consistently, I chose to share it to provide a safely
    referenceable version.
    </PStyled> 

    <h2>Explanation of Quadratic Method, by Example</h2>
    <PStyled>
    The presentation below is based on the approach in <a
    href="https://arxiv.org/abs/1910.06709">my originally posted
    article</a>, but goes further. It uses my sign convention and my own logical
    steps (as opposed to using Savage's version) in order to be logically sound,
    and also because I think my choice is helpful for understanding the deeper
    relationship between a quadratic and its solutions. It also shows a clean
    reduction of the problem from solving a standard quadratic, to a classical
    problem solved by the Babylonians and Greeks. This video is a self-contained
    practical lesson that walks through many examples with each logical step
    explained. The text discussion below goes a bit deeper and includes
    commentary which may be useful for teachers.
    </PStyled>

    <Video>
    <iframe title="myFrame" style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} src={`https://www.youtube.com/embed/XKBX0r3J-9Y`} frameBorder="0" allowFullScreen="allowFullScreen" />
    </Video>

    <h3>Review: Multiplying and Unmultiplying</h3>
    <PStyled>
    Let's start by reviewing the facts that are usually taught to introduce
    quadratic equations.  First, we use the distributive rule to multiply
    (also called <a
      href="https://www.expii.com/t/using-foil-to-multiply-binomials-4491">FOIL</a>):
    {`
      \\begin{align*}
        &\\quad (x - 3)(x - 4) \\\\
        &= x^2 - 4x - 3x + 12 \\\\
        &= x^2 - 7x + 12.
        \\end{align*}
      `}
    </PStyled>
    <PStyled>
    The key takeaway is that the $-7$ in the $-7x$ comes from adding together
    $-3$ and $-4$, and the $12$ comes from multiplying together $-3$ and $-4$.
    </PStyled>
    <PStyled>
    Here's another example:
    {`
      \\begin{align*}
        &(u - 3)(u + 3) \\\\
        &= u^2 + 3u - 3u - 9 \\\\
        &= u^2 - 9.
        \\end{align*}
      `}
    </PStyled>
    <PStyled>
    Since we had both $-3$ and $+3$, the $+3u$ and $-3u$ terms canceled
    out, giving us a difference of squares. That will be useful later.
    </PStyled>
    <PStyled>
    The reason it is useful to know what happens when multiplying is
    because if we can do this in reverse, we can solve quadratic equations.
    For example, suppose we want to find all $x$ such that

    \[x^2 - 7x + 12 = 0.\]

    We already know that this is the same (has exactly the same solutions) as

    \[(x-3)(x-4) = 0.\]

    The only way for two numbers to multiply to zero is if one (or both) are
    zero. (The formal justification of this <i>zero-product property</i> uses
      the basic axiom that you can divide by any nonzero number: suppose for
      contradiction that $ab = 0$ with both $a$ and $b$ nonzero. Then by
      dividing both sides of the equation $ab = 0$ by $a$, we get $b = 0$,
      contradiction.) 
    </PStyled>
    <PStyled>
    So, the $x$ that work are precisely those where $x-3 = 0$ (which is $x =
      3$), or $x-4 = 0$ (which is $x = 4$). Note that the solutions are the
    numbers we subtract from $x$, i.e., not $-3$ and $-4$, but $3$ and $4$.
    Importantly, these are all the solutions.
    </PStyled>
    <h3>Review: Setting Up for Factoring</h3>
    <PStyled>
    Let's try the reverse process for the example

    \[ x^2 - 2x - 24 = 0. \]

    It would be great if we could factorize it into something like

    \[(x - \quad)(x - \quad).\]

    Students haven't yet learned that it's always possible to find such a
    factorization, but our approach will also prove to them that it is
    always possible!  By the previous section, if we managed to factorize,
    then whatever ends up in those blank spaces will be the solutions. But
    what would work in those blank spaces? Two numbers which have sum $2$
    and product $-24$. The most commonly taught method is to find these
    numbers by guess and check. That can be frustrating, especially when
    there are negative numbers to try, and when the product has a lot of
    possible factorizations ($24$ has a ton of possibilities).
    </PStyled>
    <PStyled>
    As summarized in the <a href="/quadraticrelated">related work</a>, Savage's
    version has the similar calculations except that he seeks a factorization
    into the mathematically equivalent form {`$(x + \\quad)(x + \\quad)$`}.
    Then, the numbers in the blanks are the negatives of the solutions, so after
    finding the factorization, Savage negates the numbers as the final step.
    From an educational perspective, I think that it is a bit more
      advantageous to cleanly reduce the standard quadratic to a
      sum-and-product problem (with no need to return and remember to
        negate at the end), because one then gains the insight into the
      direct relationship between the coefficients and the sum and product
      of roots.
    </PStyled>
    <PStyled>
    To make this even more natural for a first-time learner, I would advocate
    introducing the concept of factoring with an initial example that has a
    negative $x$-coefficient, so that the factorization {`$(x - \\quad)(x -
      \\quad)$`} is already natural and convenient. It is also then even more
    transparent to observe the solutions via the zero-product property, because
    no negation is needed.
    </PStyled>
    <h3>Insight: Factoring Without Guessing</h3>
    <PStyled>
    Here's a way to pinpoint numbers that work without any guessing at all!  The
    sum of two numbers is $2$ when their average is $1$. So, we can try to look
    for numbers that are $1$ plus some amount, and $1$ minus the same amount.
    All we need to do is to find if there exists a $u$ such that $1+u$ and $1-u$
      work as the two numbers, and $u$ is allowed to be $0$.
    </PStyled>
    <PStyled>
    By looking for two numbers of the form $1+u$ and $1-u$, they automatically
    sum to $2$. So, we just need them to multiply to $-24$. We wish to find if
      there exists a $u$ which satisfies:

    \[(1+u)(1-u) = -24.\]

    We already saw a pattern like this, where we have a sum of two numbers,
    multiplied by their difference. The answer is always the difference of
    their squares! So, by rewriting the left hand side in equivalent form, we
    wish to find if there exists a $u$ such that

    \[1 - u^2 = -24.\]

    This is exciting! There is a lone $u^2$, and everything else is just a
    number! That means that we can finish searching for a valid $u$ by following
    our nose, instead of requiring any new methods. We want:

    \[u^2 = 25,\]

    which we can get from

    \[u = 5.\]

    So, a choice for $u$ exists! (We could alternatively have chosen $u = -5$,
      but that would end up giving the same result.) Therefore, tracing the
      logic back upward, we know that $1-5 = -4$ and $1+5 = 6$ will definitely
      be two numbers which have sum $2$ and product $-24$. The fact that those
      numbers satisfy the sum and product relations means that the factorization
      exists, which also means that we have found the full set of solutions: $x
      = 6$ or $x = -4$.
    </PStyled>
    <PStyled>
    Note that in this approach, we only need the existence of one particular
      number whose square equals another particular number. In this example, it
      is obvious that $5$ is a number whose square equals $25$. Once we have one
      such number, we can already follow through our logical steps, and we
      deduce a complete set of solutions to the original quadratic.  In
      contrast, at the corresponding step of Completing the Square, we would
      need to have a full list of all numbers which square to $25$. It is clear
      that $5$ and $-5$ should be in the list, but it is more difficult to
      answer why that is a complete list (especially when complex numbers are
        allowed as options). This detail is discussed in <a
      href="/quadraticcompsqr">further depth here</a>.
    </PStyled>
    <PStyled>
    As I noted in my <a
    href="https://arxiv.org/abs/1910.06709">complete article</a>,
    although I (like many others) independently came up with the trick of how to
    find two numbers given their sum and product, the Babylonians and Greeks
    already knew that particular trick thousands of years prior. However,
    mathematics had not been sufficiently developed for them to be able to use
    that trick on its own to solve general quadratic equations.  Specifically,
    they did not work with polynomial factoring or negative numbers (not to
      mention non-real complex numbers). For an in-depth discussion, please
    visit the <a href="/quadraticrelated">related work</a> page.
    </PStyled>
    <h3>Example of Use: a Quadratic That Can't Be Factored Easily</h3>
    <PStyled>
    Now that guessing has been eliminated, we can actually solve any
    quadratic with this method. Consider this example:

    {`
      \\[\\frac{x^2}{2} - 2x + 3 = 0.\\]
      `}

    First, let's clean it up by multiplying both sides by $2$, to obtain an
    equation whose solution set is identical:

    \[x^2 - 4x + 6 = 0.\]

    Just like before, <strong>if</strong> we can find two numbers with sum $4$
    and product $6$, then the factorization $(x - \quad)(x - \quad)$ will exist,
    and those two numbers will be the solutions. Halving the sum to get the
    average, we see that we'd be done if we can find some $u$ so that numbers of
    the form $2+u$ and $2-u$ give a product of $6$.  These two equations are
    equivalent to each other:
    {`
      \\begin{align*}
      4 - u^2 &= 6 \\\\
      u^2 &= -2
      \\end{align*}
      `}

    We can satisfy the bottom equation by choosing $u = i \sqrt{2}$.
    Importantly, the mathematical invention of complex numbers allows us to take
    the square root of a negative number, so there is a valid choice for $u$.
    (This is also why we do not need the Fundamental Theorem of Algebra, and in
      fact, why this approach proves that theorem for degree-2 polynomials.) So,
    there are indeed two numbers with sum $4$ and product $6$, and they are $2 +
    u$ and $2 - u$, which are $2 \pm i \sqrt{2}$.  The fact that those numbers
    satisfy the sum and product relations means that the factorization $(x -
      \quad)(x - \quad)$ exists, and so we have found the solutions: $x = 2 \pm
    i \sqrt{2}$. We completed the problem, and we didn't need to use any
    memorized formula at all!  <strong>This method works for every quadratic
    equation, without needing any memorization, and every step has a simple
    mathematical justification.</strong>
    </PStyled>
    <h3>Proof of the Quadratic Formula</h3>
    <PStyled>
    If one wishes to derive the quadratic formula, this method also provides an
    alternative simple proof of it.
    </PStyled>
    <PStyled>
    For a general quadratic equation $x^2 + Bx + C = 0$, the above shows
    that it suffices to find two numbers with sum $-B$ and product $C$, at
    which point the factorization will exist and those will be the roots.
    So, we'd like to find if there exists a $u$ so that the two 
      numbers {'$-\\frac{B}{2} + u$'} and {'$-\\frac{B}{2} - u$'} will work. They
      automatically sum to $-B$. Their product is $C$ precisely when these two
      equivalent equations are satisfied:
    {`
      \\begin{align*}
      \\frac{B^2}{4} - u^2 &= C \\\\
      u^2 &= \\frac{B^2}{4} -C
        \\end{align*}
      `}
    Since the square root always exists (extending to complex numbers if
      necessary), by choosing a square root of {`$\\frac{B^2}{4} - C$`} for $u$,
      we can satisfy the last equation.  Therefore, the two 
      numbers {`$-\\frac{B}{2} \\pm \\sqrt{\\frac{B^2}{4} - C}$`} have sum $-B$ and
      product $C$, and are all the solutions.
    </PStyled>
    <PStyled>
    The above formula is already enough to solve any quadratic equation, because
    you can multiply or divide both sides by a number so that nothing is in
    front of the $x^2$. However, just to see that this formula is the same as
    what everyone is used to memorizing (which is no longer necessary, in light
      of our method), we can show how to get the formula for the most general
    quadratic equation $ax^2 + bx + c = 0$ when $a \neq 0$. We just need to
    divide by $a$ first, to get the equivalent equation
    {`
      \\[
        x^2 + \\left(\\frac{b}{a}\\right) x + \\frac{c}{a} = 0.
        \\]
      `}

    Then, plugging in {'$\\frac{b}{a}$'} for $B$ and {'$\\frac{c}{a}$'} for
    $C$ in the solutions above, we get that the solutions are:
    {`
      \\begin{align*}
        & -\\frac{b}{2a} \\pm \\sqrt{\\frac{b^2}{4a^2} - \\frac{c}{a}} \\\\
        & = -\\frac{b}{2a} \\pm \\sqrt{\\frac{b^2 - 4ac}{4a^2}} \\\\
        & = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}.
        \\end{align*}
      `}
    </PStyled>

    <h2>Summary</h2>

    <PStyled>
    This method consists of two main steps, starting from a general
    quadratic equation in standard form $x^2 + Bx + C = 0$.
    </PStyled>

    <OLStyled>
    <li>Because of polynomial factoring, if we can find two numbers with
    sum $-B$ and product $C,$ then those are the complete set of
    solutions.</li>

    <li>Use the ancient Babylonian/Greek trick (extended to complex
      numbers) to find those two numbers in every circumstance.</li>
    </OLStyled>

    <PStyled>
    In order for these steps to be mathematically sound as a complete
    method, it is essential that under all circumstances, Step 2 finds two
    numbers to use in Step 1, even if they are non-real complex numbers. It
    is therefore unlikely that mathematicians before Cardano (~1500 AD)
    could have done this completely.
    </PStyled>

    <PStyled>
    Both steps are individually well-known.  In retrospect, their
    combination to form a complete and coherent method for solving general
    quadratic equations is simple and obvious. Therefore, the main
    contribution of this method is to point out something useful that has
    been hiding in plain sight.
    </PStyled>

    <h2>Historical Mathematical Manuscripts</h2>

    <PStyled>
    While researching the novelty of this approach, I came across several
    ancient mathematical works. Thanks to the Internet, it is now possible
    for everyone to view and appreciate the creativity of early
    mathematicians.
    </PStyled>

    <ULStyled>

    <li><a
    href="https://archive.org/details/diophantusofalex00heatiala/page/n6">
    Arithmetica</a>, by Diophantus (circa 250)</li>

    <li><a
    href="https://archive.org/details/Brahmasphutasiddhanta_Vol_1/page/n3">
    Brahma-Sphuta Siddhanta</a>, by Brahmagupta (circa 628)</li>

    <li><a
    href="https://www.wilbourhall.org/pdfs/The_Algebra_of_Mohammed_Ben_Musa2.pdf">
    al-Kitab al-mukhtasar fi hisab al-jabr wal-muqabala </a>, by
    al-Khwarizmi (circa 825)</li>

    <li><a
    href="http://www.filosofia.unimi.it/cardano/testi/operaomnia/vol_4_s_4.pdf">Ars
    Magna</a>, by Cardano (1545)</li>

    <li><a href="https://gallica.bnf.fr/ark:/12148/bpt6k107597d.pdf">Opera
    Mathematica</a>, by Vi&egrave;te (1579)</li>

    <li><a
    href="https://archive.org/details/geschichtederel00tropgoog/page/n265">Geschichte
    der Elementar-Mathematik in systematischer Darstellung</a>, by Tropfke
    (1902—1903)</li>

    </ULStyled>

    </Container>
    <Footer></Footer>
    </Styles>
  );
}
