import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import {PostJumbotron} from './../components/PostJumbotron';
import {Footer} from './../components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

export const QuadraticLogicExist = () => {
  return (
    <Styles>
    <PostJumbotron 
    date = 'Posted Dec 29, 2019; updated Jan 5, 2020'
    title = "Quadratic Method: Existence Logic"
    subTitle = ''>
    </PostJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
    Multiple prior references with similar work are structured by requiring
    the initial non-elementary fact that every quadratic has two roots, or
    that for any two numbers $b$ and $c$, there definitely exist a pair
    $(u, v)$ of numbers such that $u+v = b$ and $uv = c$. For example, this
    requirement is made by any derivation that cites Vi&egrave;te's
    Relations (which state that two roots definitely exist and then gives
      equations for their sum and product), and also made by any derivation
    that starts by asserting that every standard-form quadratic equation 
    is <i>equivalent to</i> the system of equations $u+v = b$ and $uv = c$ and
    then makes substitutions and deductions using those equations. To
    understand why the direction of logical deduction is important, and how
    it interacts with the existence requirement, it is helpful to consider
    the following pair of examples.
    </PStyled>

    <h2>Example Problem 1</h2>

    <PStyled>
    Find all $(u, v)$ that satisfy the system of equations:
    {`
    \\begin{align*}
    u + v &= 6 \\\\
    u - v &= 2 \\\\
    3u + v &= 14
    \\end{align*}
    `}
    </PStyled>

    <h2>"Solution" to Problem 1</h2>

    <PStyled>
    Add the first and second equations to get
    {`
    \\begin{align*}
    2u &= 8 \\\\
    u &= 4
    \\end{align*}
    `}
    Substitute this into the third equation to get
    {`
    \\begin{align*}
    12 + v &= 14 \\\\
    v &= 2
    \\end{align*}
    `}
    Therefore, $(u, v) = (4, 2)$ is the solution.
    </PStyled>

    <h2>Example Problem 2</h2>

    <PStyled>
    Find all $(u, v)$ that satisfy the system of equations:
    {`
    \\begin{align*}
    u + v &= 6 \\\\
    u - v &= 2 \\\\
    u + 3v &= 7
    \\end{align*}
    `}
    </PStyled>

    <h2>"Solution" to Problem 2</h2>

    <PStyled>
    Add the first and second equations to get
    {`
    \\begin{align*}
    2u &= 8 \\\\
    u &= 4
    \\end{align*}
    `}
    Substitute this into the third equation to get
    {`
    \\begin{align*}
    4 + 3v &= 7 \\\\
    v &= 1
    \\end{align*}
    `}
    Therefore, $(u, v) = (4, 1)$ is the solution.
    </PStyled>

    <h2>Contradiction</h2>

    <PStyled>
    Unfortunately, only one of these "Solutions" produces an answer that works.
    In Problem 1, if we substitute $(u, v) = (4, 2)$ into all 3 equations, then
    all equations hold.  However, in Problem 2, if we substitute $(u, v) = (4,
      1)$ into all 3 equations, then the first two equations fail ($4 + 1 \neq
        6$ and also $4 - 1 \neq 2$). This is one reason why when we solve
    systems of equations in this way, it is important to have a final step of
    substituting the solutions back into all of the equations to check that they
    actually work.
    </PStyled>

    <PStyled>
    Why do these methods of reasoning sometimes work and sometimes fail? The
    reason is because they all proceed by "forward" deduction, in which we
    collect true statements, and one step after another (whether the next
      sentence or the next equation in a sequential stack of equations) uses the
    entirety of the current collection of true statements to make one new
    deduction that must also be true. We then run the logical procedure until we
    narrow down the possibilities for $u$ and $v$ down to at most one option for
    each. This logical flow amounts to saying that at the start, we don't have
    any understanding of what $u$ or $v$ could be (they could be anything), and
    we take step after step narrowing down the possibilities for $u$ and $v$
    until we are down to at most one option, after which we conclude that we're
    done.
    </PStyled>

    <PStyled>
    The logical error is in concluding that if there is at most one option, then
    there is always exactly one option. There could be zero options that work.
    Why does Solution 1 work while Solution 2 doesn't? It's because it just so
    happens that in Problem 1 a solution does exist. So, once the options for
    $(u, v)$ have been narrowed down to one remaining option, that must indeed
    be it. On the other hand, in Problem 2 no solution exists. So, logically
    Solution 2 has narrowed down the possibilities to: "if a solution exists at
    all, $u$ must be $4$ and $v$ must be $1$". That is compatible with the fact
    that no solution exists, so it is incorrect to stop there and claim $(4,
      1)$ is the solution.
    </PStyled>

    <h2>Resolution</h2>

    <PStyled>
    Methods that use forward deductions like the above can be made
    mathematically correct by inserting an initial required fact that a solution
    definitely exists. Then, the narrowing-down procedure becomes legitimate
    because it ultimately narrows down the possibilities to at most one precise
    choice, and using the initial required fact (a solution exists), that single
    remaining possibility must be it.
    </PStyled>

    <PStyled>
    In the context of quadratic equations, the fact that a pair of numbers
    definitely exists with sum $b$ and product $c$ is true, but not elementary.
    The Babylonians and Greeks didn't even believe it to be true in general,
    because it often involves imaginary numbers. For example, the Babylonians
    and Greeks didn't think that there were two numbers that add to $0$ and
    multiply to $1$. (Today, we know that $\pm i$ work.) Instead, this is a
    consequence of the Fundamental Theorem of Algebra for quadratic polynomials,
    which states that every quadratic has exactly 2 roots. The easiest proof of
    that fact is by the quadratic formula, but then it is not as useful to rely
    on a different proof of the quadratic formula as the first step in proving
    the quadratic formula. Several people in the past did that as a way not to
    prove the quadratic formula, but to come up with an easy way to re-deduce
    it, often en route to a more sophisticated Algebraic result. A record of
    discussion around Venezuelan mathematicians Francisco Duarte and Luis Báez
    Duarte, who recorded this in 1999 in the context of the cubic formula, is
    resolved in this pair of recent Spanish-language articles: <a
    href="https://douglas-cine-matica.blogspot.com/2019/12/una-reflexion-novedosa-acerca-de-un-muy.html">part
    1</a> and <a
    href="https://douglas-cine-matica.blogspot.com/2019/12/una-reflexion-novedosa-acerca-de-un-muy_22.html">part
    2</a>.
    </PStyled>

    <h2 id="hardreverse">Example of Difficult-to-Reverse Use of Existence</h2>

    <PStyled>
    In general, one cannot naively reverse the logical direction of a proof
    which starts with an initial existence requirement. In the context of
    quadratic equations, the following independently interesting <a
    href="https://www.jstor.org/stable/pdf/27965986.pdf">proof attributed to
    Thomas Harriot (1560–1621)</a> demonstrates the difficulty. Consider a
    general quadratic equation $x^2 + Bx + C = 0$.
    </PStyled>

    <OLStyled>

    <li>There definitely exist two numbers $r$ and $s$ such that the quadratic can be factorized as 
    \[ x^2 + Bx + C = (x-r)(x-s) \]
    </li>

    <li>By comparing coefficients in the expansion, 
    \[ r+s = -B \]
    </li>
    
    <li>By comparing coefficients in the expansion, 
    \[ rs = C \]
    </li>

    <li>Squaring both sides of Step 2, 
    \[ (r+s)^2 = B^2 \]
    </li>

    <li>Taking Step 4 and subtracting the quadruple of Step 3, 
    \[ (r+s)^2 - 4rs = B^2 - 4C \]
    </li>

    <li>Simplifying the left side of Step 5,
    \[ (r-s)^2 = B^2 - 4C \]
    </li>

    <li>Taking the square root of both sides of Step 6, 
    {`\\[ r-s = \\pm \\sqrt{B^2 - 4C} \\]`}
    </li>

    <li>Adding Step 2 and Step 7, and then dividing by 2,
    {`\\[ r = \\frac{-B \\pm \\sqrt{B^2 - 4C}}{2} \\]`}
    </li>

    <li>Subtracting Step 8 from Step 2,
    {`\\[ s = \\frac{-B \\mp \\sqrt{B^2 - 4C}}{2} \\]`}
    </li>

    <li>There are two sign choices that arise in Step 7. Either choice of sign
    propagates through Steps 8 and 9 to produce the same unordered pair of
    numbers $r$ and $s$:
    {`\\[ \\frac{-B \\pm \\sqrt{B^2 - 4C}}{2} \\]`}
    </li>
    
    <li>Step 1 (definite existence of the unordered pair) together with Step 10
    (only one remaining possibility for the unordered pair) imply that the two
    numbers in Step 10 are the two precise numbers in the factorization in Step
    1; by the zero-product property, they are the complete set of roots.</li>

    </OLStyled>

    <PStyled>
    If one wishes to remove the initial assumption (Step 1), it is not possible
    to naively reverse the deductive steps. For a clear obstacle, observe that
    while Step 2 obviously implies Step 4, unfortunately Step 4 does not imply
    Step 2. Indeed, Step 4 implies that $r+s = \pm B$, with no immediate control
    on whether it is $+B$ or $-B$, even though Step 2 requires $-B$ exclusively.
    This example shows that in general, in order to remove an initial existence
    assumption, care must be taken to assemble the logical steps.
    </PStyled>

    </Container>
    <Footer></Footer>
    </Styles>
  );
}
