import React from 'react';
import styled from 'styled-components';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import {CustomCard} from './components/CustomCard';
import ImgHeader from './assets/back_news.jpg';
import ImgNews20150314 from './assets/news/news_20150314.jpg';
import ImgNews20150602 from './assets/news/news_20150602.jpg';
import ImgNews20160718 from './assets/news/news_20160718.jpg';
import ImgNews20170722 from './assets/news/news_20170722.jpg';
import ImgNews20190826 from './assets/news/news_20190826.jpg';
import ImgNews20200205 from './assets/news/news_20200205.jpg';
import ImgNews20200317 from './assets/news/news_20200317.jpg';
import ImgNews20200722 from './assets/news/news_20200722.webp';
import ImgNews20210208 from './assets/news/news_20210208.jpg';
import ImgNews20210216 from './assets/news/news_20210216.jpg';
import ImgNews20211019 from './assets/news/news_20211019.jpg';
import ImgNews20220125 from './assets/news/news_20220125.webp';
import ImgNews20220128 from './assets/news/news_20220128.png';
import ImgNews20221227 from './assets/news/news_20221227.webp';
import ImgNews20230525 from './assets/news/news_20230525.webp';
import ImgNews20230608 from './assets/news/news_20230608.jpg';
import ImgNews20230623 from './assets/news/news_20230623.jpg';
import ImgNews20240317 from './assets/news/news_20240317.webp';
import ImgNews20240318 from './assets/news/news_20240318.jpg';

const Styles = styled.div`
    .row {
        margin-left: 0px;
        margin-right: 0px;
        font-family: 'Montserrat', sans-serif;
    }

    .col {
        text-align: center;
    }
`;


export const News = () => {
    return (
        <Styles>
            <CustomJumbotron bgimage={ImgHeader}>News</CustomJumbotron>
            <div style = {{ backgroundColor: '#cfcfcf'}}>
                <Container style = {{paddingTop: 30, paddingBottom: 70}} >
                    <Row>
                        <CustomCard subTitle = 'March 18, 2024 — CNN' 
                                    title = "Why this math professor is putting actors in classrooms"
                                    image = {ImgNews20240318}
                                    link = "https://www.cnn.com/world/professor-po-shen-loh-actors-classrooms-spc">
                        </CustomCard>
                        <CustomCard subTitle = 'March 17, 2024 — South China Morning Post' 
                                    title = "ChatGPT’s great at maths? No problem, celebrity professor tells Hong Kong students…"
                                    image = {ImgNews20240317}
                                    link = "https://www.scmp.com/news/hong-kong/society/article/3255693/chatgpts-great-maths-no-problem-celebrity-professor-tells-hong-kong-students-humans-win-thinking">
                        </CustomCard>
                        <CustomCard subTitle = 'June 23, 2023 — TheMarker (Haaretz Group, Israel)' 
                                    title = "תהיה מלחמה בין בני אדם לרובוטים — והמטרה שלי היא שבני האדם ינצחו"
                                    image = {ImgNews20230623}
                                    link = "https://www.themarker.com/weekend/2023-06-23/ty-article-magazine/.premium/00000188-de28-de38-a7ea-deedcc320000">
                        </CustomCard>
                        <CustomCard subTitle = 'June 8, 2023 — Fox Business' 
                                    title = "AI will spur entrepreneurialism, make everyone a ‘mini-boss’"
                                    image = {ImgNews20230608}
                                    link = "https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc">
                        </CustomCard>
                        <CustomCard subTitle = 'May 25, 2023 — Wall Street Journal' 
                                    title = "The Brilliant Math Coach Teaching America’s Kids to Outsmart AI"
                                    image = {ImgNews20230525}
                                    link = "https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc">
                        </CustomCard>
                        <CustomCard subTitle = 'December 27, 2022 — WESA (National Public Radio station)' 
                                    title = "Meet the Pittsburgh professor making math as cool as TikTok"
                                    image = {ImgNews20221227}
                                    link = "https://www.wesa.fm/education/2022-12-27/meet-the-pittsburgh-professor-making-math-as-cool-as-tiktok">
                        </CustomCard>
                        <CustomCard subTitle = 'January 28, 2022 — Bloomberg | Washington Post' 
                                    title = "Contact Tracing Didn't Defeat Covid. Here's How It Could"
                                    image = {ImgNews20220128}
                                    link = "https://www.bloomberg.com/opinion/articles/2022-01-28/contact-tracing-didn-t-defeat-covid-here-s-how-it-could">
                        </CustomCard>
                        <CustomCard subTitle = 'January 25, 2022 — Australian Broadcasting Corporation News' 
                                    title = "As Omicron spreads across the world, what does the future of contact tracing look like?"
                                    image = {ImgNews20220125}
                                    link = "https://www.abc.net.au/news/2022-01-26/omicron-spread-what-is-the-future-of-contact-tracing/100770948">
                        </CustomCard>
                        <CustomCard subTitle = 'October 19, 2021 — Straits Times' 
                                    title = "US prof keen to work with S'pore on app that warns users before they are exposed to Covid-19"
                                    image = {ImgNews20211019}
                                    link = "https://www.straitstimes.com/tech/tech-news/us-prof-keen-to-work-with-spore-on-app-that-warns-users-before-they-are-exposed-to">
                        </CustomCard>
                        <CustomCard subTitle = 'February 16, 2021 — Quanta' 
                                    title = "The Coach Who Led the U.S. Math Team Back to the Top"
                                    image = {ImgNews20210216}
                                    link = "https://www.quantamagazine.org/po-shen-loh-led-the-u-s-math-team-back-to-first-place-20210216/">
                        </CustomCard>
                        <CustomCard subTitle = 'February 8, 2021 — CNN Philippines' 
                                    title = "Mobile app uses Bluetooth in detecting people with COVID-19"
                                    image = {ImgNews20210208}
                                    link = "https://cnnphilippines.com/news/2021/2/8/Mobile-app-uses-Bluetooth-in-detecting-people-with-COVID-19.html">
                        </CustomCard>
                        <CustomCard subTitle = 'July 22, 2020 — Washington Examiner' 
                                    title = "A personal COVID-19 radar could be key to resuming daily life"
                                    image = {ImgNews20200722}
                                    link = "https://www.washingtonexaminer.com/opinion/a-personal-covid-19-radar-could-be-key-to-resuming-daily-life">
                        </CustomCard>
                        <CustomCard subTitle = 'March 17, 2020 — Popular Mechanics' 
                                    title = "This Professor Will Teach You Math for Free While You're Social Distancing"
                                    image = {ImgNews20200317}
                                    link = "https://www.popularmechanics.com/science/math/a31474269/learn-math-free-live-stream/">
                        </CustomCard>
                        <CustomCard subTitle = 'February 5, 2020 — New York Times' 
                                    title = 'This Professor’s ‘Amazing’ Trick Makes Quadratic Equations Easier'
                                    image = {ImgNews20200205}
                                    link = "https://www.nytimes.com/2020/02/05/science/quadratic-equations-algebra.html">
                        </CustomCard>
                        <CustomCard subTitle = 'August 21, 2019 — New York Times' 
                                    title = 'How Many Triangles Are There? Here’s How to Solve the Puzzle'
                                    image = {ImgNews20190826}
                                    link = "https://www.nytimes.com/2019/08/21/science/math-equation-triangles-pemdas.html">
                        </CustomCard>
                        <CustomCard subTitle = 'July 22, 2017 — CBS' 
                                    title = "America's Best Young Math Minds Compete in Rio"
                                    image = {ImgNews20170722}
                                    link = "https://www.cbsnews.com/news/americas-best-young-math-minds-compete-in-rio/">
                        </CustomCard>
                        <CustomCard subTitle = 'July 18, 2016 — Washington Post' 
                                    title = 'U.S. Students Win Prestigious International Math Olympiad'
                                    image = {ImgNews20160718}
                                    link = "https://www.washingtonpost.com/news/answer-sheet/wp/2016/07/18/u-s-students-win-prestigious-international-math-olympiad-for-second-straight-year/">
                        </CustomCard>
                        <CustomCard subTitle = 'June 2, 2015 — Forbes' 
                                    title = 'Next-Gen Educational Tools Deliver A Big Dose Of Fun'
                                    image = {ImgNews20150602}
                                    link = "https://www.forbes.com/sites/oracle/2015/06/02/next-gen-educational-tools-deliver-a-big-dose-of-fun/">
                        </CustomCard>
                        <CustomCard subTitle = 'March 14, 2015 — NPR' 
                                    title = "Pi Day Isn't Just Magical, It's Mathematics. And There's Pie!"
                                    image = {ImgNews20150314}
                                    link = "https://www.npr.org/sections/thesalt/2015/03/14/392589847/pi-day-isnt-just-magical-its-mathematics-and-theres-pie">
                        </CustomCard>
                    </Row>
                </Container>
            </div>
            <Footer></Footer>
        </Styles>
    );
}
