import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgTourMap from './assets/tour-map-2023-summer.jpg';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;


export const Tour2023 = () => {
  return (
    <Styles>
    <CustomJumbotron bgimage={ImgHeader}>2023 Tour</CustomJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
      This page is preserved for reference. To attend a future event, go to
      the <Link to="/tour">current tour page</Link>.
    </PStyled>

    <h2>
      2023 Tour Stops
    </h2>

    <img src={ImgTourMap} alt="2023 Tour Stop Map" style={{maxWidth: "100%"}}></img>

    <h2>
      Summer 2023 Tour
    </h2>

    <PStyled>
      In the summer of 2021, Prof. Loh conducted the largest outdoor solo
      math tour in human history, <Link to="/tour2021">visiting 40 cities
        in 7 weeks</Link>, holding events in public parks. It was so
      successful that he continued the tour in 2022 and 2023 with higher
      intensity.  These fun events inspired students to see math from a
      different angle and to embrace challenge, while also sharing
      educational strategies with parents.
    </PStyled>

    <OLStyled>
      <li><a href="https://simons.berkeley.edu/events/theoretically-speaking-building-human-intelligence-scale-save-next-generation-chatgpt">Berkeley, CA (Simons Institute Public Lecture): Tue Apr 4 at 6:00pm</a></li>
      <li><a href="https://www.stanfordmathtournament.com/competitions/smt-2023">San Francisco, CA (Stanford): Sat Apr 8 morning, to parents at Stanford Math Tournament</a></li>
      <li><a href="https://www.eventbrite.com/e/604043389037">San Francisco, CA (Milpitas): Sat Apr 8 at 1:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/594954152887">San Francisco, CA (Milpitas): Sat Apr 8 at 3:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/592595427877">San Francisco, CA (Saratoga): Sun Apr 9 at 1:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/594878456477">San Francisco, CA (Menlo Park): Sun Apr 9 at 4:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/612488779427">San Francisco, CA (Cupertino): Thu Apr 13 at 5:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/609787309257">San Francisco, CA (Sunnyvale): Fri Apr 14 at 7:00pm</a></li>
      <li><a href="https://bmt.berkeley.edu/bmmt-2023/">San Francisco, CA (Berkeley): Sat Apr 15 at 9:15am in Dwinelle 155</a>, parent talk at Berkeley mini Math Tournament</li>
      <li><a href="https://mbmt.mbhs.edu">Silver Spring, MD: Sun Apr 16 at 2:30pm</a>, at Montgomery Blair Math Tournament</li>
      <li><a href="https://www.eventbrite.com/e/611436853087">San Diego, CA: Tue Apr 18 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/570925191617">New Britain, CT: Sun Apr 23 at 12:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601852034637">Houston, TX (Sugar Land): Sat Apr 29 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/601853709647">Houston, TX (Katy): Sat Apr 29 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601858263267">Houston, TX (The Woodlands): Sat Apr 29 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601859145907">Dallas, TX (Plano): Sun Apr 30 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/601859416717">Dallas, TX (Plano): Sun Apr 30 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601859597257">Dallas, TX (Plano): Sun Apr 30 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601861964337">Austin, TX: Mon May 1 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601866718557">San Antonio, TX: Tue May 2 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601868263177">Salt Lake City, UT: Wed May 3 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/631061500937">Boston, MA (Watertown): Fri May 5 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601873589107">Reading, MA: Sat May 6 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/601877701407">Lexington, MA: Sat May 6 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601878022367">Weston, MA: Sat May 6 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601878303207">Westchester, NY: Sun May 7 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/601878584047">Westchester, NY: Sun May 7 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601879366387">Bergen County, NJ: Tue May 9 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601883298147">Basking Ridge, NJ: Wed May 10 at 6:00pm</a></li>
      <li>Syosset, NY (Syosset High School): Thu May 11 at 2:30pm</li>
      <li><a href="https://www.eventbrite.com/e/601883528837">Syosset, NY: Thu May 11 at 6:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601884010277">Bayside, NY: Fri May 12 at 6:30pm</a></li>
      <li><a href="https://www.mathcounts.org/programs/national-competition-schedule-glance">Orlando, FL (National MATHCOUNTS Competition)</a>: Sun May 14 and Mon May 15, but no public talk</li>
      <li><a href="https://www.eventbrite.com/e/607884186967">Detroit, MI: Tue May 16 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/605253017067">Chicago, IL (Naperville): Wed May 17 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/607887406597">Toronto, Canada: Thu May 18 at 7:30pm</a></li>
      <li>New York, NY (<a href="https://nestmk12.net/">NEST+m</a>): Fri May 19 at 11:00am</li>
      <li><a href="https://www.eventbrite.com/e/601889346237">West Windsor, NJ: Fri May 19 at 6:30pm</a></li>
      <li><a href="https://www.stevens.edu/stevens-mathematical-olympiad">Hoboken, NJ (Stevens Institute of Technology): Sat May 20</a>, for parents and students at Stevens Mathematical Olympiad.</li>
      <li><a href="https://www.eventbrite.com/e/607878018517">Monmouth County, NJ: Sat May 20 at 1:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/607885671407">Philadelphia, PA (Wayne): Sat May 20 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601932996797">Montgomery County, MD: Sun May 21 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/601933658777">Montgomery County, MD: Sun May 21 at 12:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601935745017">Fairfax, VA: Sun May 21 at 4:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601937680807">Fairfax, VA: Sun May 21 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601940338757">Ellicott City, MD: Mon May 22 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601983046497">Raleigh, NC: Tue May 23 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/601984571057">Charlotte, NC: Wed May 24 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/602002895867">Atlanta, GA: Thu May 25 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/602004069377">Seattle, WA (Bellevue): Fri May 26 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/633097390337">Vancouver, Canada: Sat May 27 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/623957342207">Vancouver, Canada: Sat May 27 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/623991735077">Vancouver, Canada: Sat May 27 at 3:15pm in Mandarin (中文)</a></li>
      <li><a href="https://www.eventbrite.com/e/633099616997">Vancouver, Canada: Sun May 28 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/633103809537">Vancouver, Canada: Sun May 28 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/602006526727">Seattle, WA: Mon May 29 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/607875922247">Seattle, WA: Mon May 29 at 4:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/643216175897">Seattle, WA: Mon May 29 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/602012885747">Portland, OR: Tue May 30 at 7:15pm</a></li>
      <li><a href="https://www.eventbrite.com/e/602014831567">Los Angeles, CA (Ontario): Wed May 31 at 6:00pm</a></li>
      <li><a href="https://www.intindstat.org/conference2023/index">Denver, CO (International Indian Statistical Association Conference, Colorado School of Mines): Thu Jun 1 at 11:20am</a></li>
      <li><a href="https://www.eventbrite.com/e/602016616907">Denver, CO: Thu Jun 1 at 6:45pm</a></li>
      <li><a href="https://www.arml.com/ARML/arml_2019/page/index.php">State College, PA (American Regions Math League): Fri Jun 2 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/625361090857">Cleveland, OH: Mon Jul 17 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/625366035647">Columbus, OH: Tue Jul 18 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/625369576237">Cincinnati, OH: Wed Jul 19 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/625373698567">Indianapolis, IN: Thu Jul 20 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/625377911167">West Lafayette, IN: Fri Jul 21 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642311800887">Chicago, IL: Sat Jul 22 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/681637956477">Chicago, IL (Deerfield): Sat Jul 22 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642412401787">Milwaukee, WI: Sat Jul 22 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642391900467">Champaign, IL: Sun Jul 23 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642396765017">St. Louis, MO: Sun Jul 23 at 4:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642370055127">Kansas City, MO: Mon Jul 24 at 7:30pm</a></li>
      <li><a href="https://momath.org/chatgpt/">New York City, NY: Tue Jul 25 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642423504997">Minneapolis, MN: Thu Jul 27 at 6:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642431218067">Oklahoma City, OK: Fri Jul 28 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642439793717">Dallas, TX: Sat Jul 29 at 10:30am</a></li>
          <li><a href="https://www.eventbrite.com/e/642441248067">Dallas, TX: Sat Jul 29 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642442481757">Houston, TX: Sun Jul 30 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642443314247">Houston, TX: Sun Jul 30 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642445781627">San Diego, CA: Sun Aug 6 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642447476697">Baton Rouge, LA: Tue Aug 8 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642451047377">Jackson, MS: Wed Aug 9 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642454708327">Memphis, TN: Thu Aug 10 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642459141587">Nashville, TN: Fri Aug 11 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642462451487">Huntsville, AL: Sat Aug 12 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642465119467">Birmingham, AL: Sat Aug 12 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642467195677">Atlanta, GA: Sun Aug 13 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642468960957">Atlanta, GA: Sun Aug 13 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642479031077">Charlotte, NC: Mon Aug 14 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642481197557">Raleigh, NC: Tue Aug 15 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642360456417">Arlington, VA: Wed Aug 16 at 3:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642387908527">Fairfax, VA: Wed Aug 16 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642396584477">Bethesda, MD: Thu Aug 17 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642401519237">Philadelphia, PA: Fri Aug 18 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642405481087">Princeton, NJ: Sat Aug 19 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642409984557">Edison, NJ: Sat Aug 19 at 4:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642413334577">Manhattan Upper West Side, NY: Sun Aug 20 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642425360547">Manhasset, NY: Sun Aug 20 at 3:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642493293737">Greenwich, CT: Mon Aug 21 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642431248157">Bergen County, NJ: Tue Aug 22 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642435811807">State College, PA: Wed Aug 23 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642438901047">San Francisco, CA (Dublin): Fri Aug 25 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642442180857">San Francisco, CA (Cupertino/Sunnyvale): Sat Aug 26 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642444969197">San Francisco, CA (Cupertino/Sunnyvale): Sat Aug 26 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/706098538737">San Francisco, CA (Cupertino/Sunnyvale): Sat Aug 26 at 4:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642446995257">San Francisco, CA (Fremont): Sat Aug 26 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642448840777">San Francisco, CA (Palo Alto): Sun Aug 27 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642450636147">San Francisco, CA (Palo Alto): Sun Aug 27 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642454567907">Los Angeles, CA (Arcadia): Mon Aug 28 at 7:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642458238887">Los Angeles, CA (Torrance): Tue Aug 29 at 7:30pm</a></li>
    </OLStyled>

    <h2>
      Fall 2023 Tour
    </h2>

    <PStyled>
      To work around his weekday teaching schedule at Carnegie Mellon
      University, Prof. Loh switched to a weekend schedule, and continued
      his speaking tour through Fall 2023 and Spring 2024.
    </PStyled>

    <OLStyled>
      <li><a href="https://www.eventbrite.com/e/642464698207">Las Vegas, NV: Sat Sep 2 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642466985047">Phoenix, AZ: Sun Sep 3 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642469071287">Albuquerque, NM: Mon Sep 4 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/703516024367">Toronto, Canada (Oakville): Sat Sep 9 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/642485771237">Toronto, Canada (York University): Sat Sep 9 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/642488649847">Toronto, Canada (York University): Sat Sep 9 at 4:00pm</a></li>
      <li>New York City, NY (Stuyvesant High School): Fri Sep 22 at 8:00am</li>
      <li><a href="https://www.eventbrite.com/e/642470525637">Tampa, FL: Sat Sep 23 at 11:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/642487205527">Fort Myers, FL: Sat Sep 23 at 4:00pm</a></li>
      <li>Washington, D.C. area (Veritas Academy): Mon Sep 25 at 7:30pm</li>
      <li><a href="https://andovermathopen.github.io">Math Open at Andover: Sat Oct 7</a></li>
      <li><a href="https://tjvmt.com/tjimo">Fairfax, VA (Thomas Jefferson Intermediate Math Open): Sat Oct 14</a></li>
      <li>Philippines: Fri Oct 20</li>
      <li><a href="https://form.jotform.com/232916719723160">Los Angeles, CA (Palos Verdes): Mon Oct 23 at 7:00pm</a></li>
      <li><a href="https://cmimc.math.cmu.edu/cmwmc">Pittsburgh, PA (Carnegie Mellon Women's Mathematics Competition): Sat Oct 28</a></li>
      <li>Albuquerque, NM (West Mesa High School): Mon Oct 30 at 8:45am</li>
      <li>Albuquerque, NM (Sandia National Laboratories): Mon Oct 30 at 12:00pm</li>
      <li>Albuquerque, NM (Jefferson Middle School): Mon Oct 30 at 3:30pm</li>
      <li>Albuquerque, NM (Albuquerque Math Olympiad): Mon Oct 30 at 4:30pm</li>
      <li>Long Island, NY (Brookhaven Math Circle): Sat Nov 4</li>
      <li><a href="https://form.jotform.com/232956375301154">Long Island, NY (Manhasset): Sat Nov 4 at 2:30pm</a></li>
      <li><a href="https://www.nymathcircle.org/registration?c=F23SCOLPO">New York City (Columbia University): Sun Nov 5 at 10:00am</a></li>
      <li>Boston, MA (Harvard-MIT Math Tournament): Sat Nov 11</li>
      <li>New York City, NY (National Museum of Mathematics): Sat Nov 11 at 6:00pm</li>
      <li>Nashville, TN (Montgomery Bell Academy): Mon Nov 13</li>
      <li><a href="https://jason-shi-f9dm.squarespace.com/">Princeton, NJ (Princeton Math Competition): Sat Nov 18 at 10:00am</a></li>
      <li>Princeton, NJ (Princeton Middle School): Sat Nov 18 at 3:30pm</li>
      <li>Calgary, Canada (Louis Riel School): Tue Nov 21 at 6:30pm</li>
      <li>Calgary, Canada (Westmount School): Wed Nov 22 at 4:00pm</li>
      <li>Calgary, Canada (University of Calgary): Wed Nov 22 at 7:00pm</li>
      <li>Vancouver, Canada: Thu Nov 23 at 1:30pm</li>
      <li>Toronto, Canada: Fri Nov 24 at 7:00pm</li>
      <li>Toronto, Canada: Sat Nov 24 at 3:00pm</li>
      <li>Potomac, MD (Bullis School): Mon Dec 4 at 6:00pm</li>
      <li>Miami, FL (American Heritage School Math Invitational for Middle Schools: Sat Dec 9</li>
      <li>San Diego, CA (University of California San Diego): Sun Dec 10</li>
      <li>Irvine, CA: Tue Dec 12 at 6:00pm</li>
      <li>Boston, MA (Lexington Math Tournament): Sat Dec 16</li>
      <li>New York City, NY (National Museum of Mathematics): Sat Dec 17 at 6:30pm</li>
    </OLStyled>

    <h2 id="asia">
      Winter 2023 Tour
    </h2>

    <PStyled>
      During the winter holidays at the end of 2023, Prof. Loh took the
      tour to Asia. Most of the talks in mainland China were given in Chinese.
    </PStyled>

    <OLStyled>
      <li><a href="https://en.wikipedia.org/wiki/Seoul_Science_High_School">Seoul, Korea (Seoul Science High School): Thu Dec 21</a></li>
      <li>Guangzhou, China (Huashi School 广州市天河区华实学校): Fri Dec 22 at 1:00pm</li>
      <li>Guangzhou, China (Huahai International School 华海国际学校): Fri Dec 22 at 3:00pm</li>
      <li><a href="https://en.wikipedia.org/wiki/Affiliated_High_School_of_South_China_Normal_University">Guangzhou, China (Affiliated High School of South China Normal University 华南师范大学附属中学): Fri Dec 22 at 7:00pm</a></li>
      <li><a href="https://www.tsinghua-sz.org/">Shenzhen, China (Shenzhen Tsinghua University Research Institute 深圳清华大学研究院): Sat Dec 23 at 10:00am</a></li>
      <li><a href="https://www.siem.sem.tsinghua.edu.cn/">Shenzhen, China (Tsinghua Shenzhen Institute of Economics and Management 清华经管深圳院): Sat Dec 23 at 3:00pm</a></li>
      <li>Shanghai (DBS Tower 浦东陆家嘴环路1318号星展银行大厦): Sun Dec 24 at 10:30am</li>
      <li>Shanghai (DBS Tower 浦东陆家嘴环路1318号星展银行大厦): Sun Dec 24 at 2:30pm</li>
      <li><a href="https://www.fdsm.fudan.edu.cn/">Shanghai, China (Fudan University School of Management 复旦大学管理学院): Sun Dec 24 at 7:00pm</a></li>
      <li>Shanghai (Shanghai Foreign Language School 上海外国语大学附属外国语学校): Mon Dec 25 at 4:00pm</li>
      <li>Shanghai, China (Shanghai East Experimental School 上海市实验学校东校): Tue Dec 26 at 9:10am</li>
      <li>Shanghai (Jianping West School 市建平中学西校): Tue Dec 26 at 2:00pm</li>
      <li><a href="https://en.shphschool.com/">Shanghai (Pinghe School 平和双语学校): Tue Dec 26 at 6:15pm</a></li>
      <li><a href="https://www.wlsashanghaiacademy.com/">Shanghai (WLSA Shanghai Academy): Wed Dec 27 at 4:30pm</a></li>
      <li>Shanghai (The Bund Education 外滩教育): Wed Dec 27 at 8:30pm</li>
      <li>Beijing (Beijing Number 8 High School 北京八中): Thu Dec 28 at 12:30pm</li>
      <li><a href="https://www.uchicago.edu/en/education-and-research/center/center_in_beijing">Beijing (University of Chicago Center in Beijing): Thu Dec 28 at 7:30pm</a></li>
      <li>Beijing (Beijing Number 2 Experimental Primary School 北京第二实验小学): Fri Dec 29 at 10:30am</li>
      <li><a href="https://www.sdsz.com.cn/">Beijing (Experimental High School Affiliated with Beijing Normal University 北京师范大学附属实验中学): Fri Dec 29 at 2:00pm</a></li>
      <li><a href="https://www.ideas-camp.com/">Beijing (Ideas Camp 启行营地): Sat Dec 30 at 2:30pm</a></li>
      <li>Beijing (University of Chicago Alumni): Mon Jan 1 at 3:00pm</li>
      <li><a href="https://www.etuschool.org">Beijing (Etu School 一土学校): Tue Jan 2 at 1:00pm</a></li>
      <li><a href="https://www.bnds.cn">Beijing (Beijing National Day School 十一学校): Tue Jan 2 at 4:30pm</a></li>
      <li>Hangzhou, China (Greentown Yuhua Primary School 杭州绿城育华小学): Wed Jan 3 at 10:00am</li>
      <li><a href="https://bihz.basischina.com/">Hangzhou, China (BASIS International School): Wed Jan 3 at 4:30pm</a></li>
      <li>Hangzhou, China (Information Harbor 杭州湾信息港): Wed Jan 3 at 7:30pm</li>
      <li>Ningbo, China (Huamao Foreign Language School 宁波华茂外国语学校): Thu Jan 4 at 2:00pm</li>
      <li>Suzhou, China (Suzhou Foreign Language School 苏州外国语学校): Fri Jan 5 at 2:00pm</li>
      <li>Shanghai, China (DBS Tower 浦东陆家嘴环路1318号星展银行大厦): Sat Jan 6 at 12:50pm</li>
      <li>Shanghai, China (Qibao Dwight High School 上海七宝德怀特高级中学): Sat Jan 6 at 3:00pm</li>
      <li>Shenzhen, China (Shenzhen Experimental School 深圳实验学校): Sun Jan 7 at 10:00am</li>
      <li>Shenzhen, China (Green Oasis School 城市绿洲学校): Sun Jan 7 at 3:30pm</li>
      <li>Shenzhen, China: Sun Jan 7 at 8:00pm</li>
      <li>Shenzhen, China: Mon Jan 8 at 10:00am</li>
      <li>Shenzhen, China (Shen Wai International School 深圳外国语学校): Mon Jan 8 at 3:45pm</li>
      <li>Shenzhen, China (BASIS International School): Mon Jan 8 at 6:00pm</li>
      <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 11:50am</a></li>
      <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 2:00pm</a></li>
      <li><a href="https://en.wikipedia.org/wiki/German_Swiss_International_School">Hong Kong (German Swiss International School): Tue Jan 9 at 5:00pm</a></li>
      <li>Hong Kong (Victoria Shanghai Academy): Wed Jan 10 at 1:30pm</li>
      <li>Hong Kong (Dalton School): Wed Jan 10 at 7:00pm</li>
      <li>Hong Kong (St. Margaret's Co-educational English Secondary School): Thu Jan 11 at 3:30pm</li>
    </OLStyled>

    <h2>Stay Notified</h2>

    <PStyled>
      To stay notified about future tour stops, please subscribe to our <a
      href="https://eepurl.com/gExYzT">email newsletter</a> which sends
      timely information about math education and contest deadlines, every
      two weeks. If your language preference is Chinese, please follow the
      WeChat Public Account: 罗博深数学.
    </PStyled>

    <PStyled>
      To follow future online live streams, please subscribe to our <a
      href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
        Challenge with Po-Shen Loh YouTube Channel</a>.
    </PStyled>

    <h2>Learn Math</h2>

    <PStyled>
      To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
    </PStyled>


    </Container>
    <Footer></Footer>
    </Styles>
  );
}
