import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import {PostJumbotron} from './../components/PostJumbotron';
import {Footer} from './../components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

export const QuadraticCompSqr = () => {
  return (
    <Styles>
    <PostJumbotron 
    date = 'Posted Dec 15, 2019'
    title = "Quadratic Method: Completing the Square"
    subTitle = ''>
    </PostJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
    Some have expressed concerns that the method I shared is exactly the same as
    the traditional method of Completing the Square.  The algebraic
    manipulations of expressions are very similar. However, there is a
    difference in the logical steps, which is evidenced by differing
    requirements on initial assumptions. This is a logical distillation of
    Completing the Square as commonly learned:
    </PStyled>

    <OLStyled>

    <li>By adding and subtracting terms from both sides, and combining terms,
    the quadratic equation $x^2 + Bx + C = 0$ is equivalent to a form $(x+D)^2 =
    E$ for some specific expressions $D$ and $E$ in terms of $B$ and $C$. This
    is equivalent in the sense that the two equations have the same set of
    roots.</li>

    <li>The complete set of numbers that satisfy $v^2 = E$ is {`$\\{\\sqrt{E}, -\\sqrt{E}\\}$`}, and no other numbers square to $E$.</li>

    <li>Therefore, $(x+D)^2 = E$ if and only if $x+D$ is equal to one of {`$\\pm
      \\sqrt{E}$`}. We then get the complete set of solutions to the original
      quadratic.</li>

    </OLStyled>

    <PStyled>
    When compared to the <a href="/quadraticdetail#method">method I shared</a>,
    Step 2 above assumes that the complete set of square roots of every number
    is known, whereas Step 4 in the method I shared just needs one working
    square root.
    </PStyled>

    <PStyled>
    Depending on the context, existence assumptions can be much easier to
    settle than complete-set assumptions. As an analogous example: it is easy to
    observe that $v^3 = 8$ has at least one solution, because $2^3 = 8$.  Many
    students initially think that there is only that one cube root.  Only later
    do they find out that {`$-1 \\pm i\\sqrt{3}$`} also have cubes equal to 8.
    So, the assumption that there are no other complex numbers that square to
    $E$ other than {`$\\pm \\sqrt{E}$`} actually requires further justification.
    </PStyled>

    <PStyled>
    If one wishes to make Completing the Square more complete (at least to match
      the method I shared), then some of the simplest ways return to the
    notions of factoring and the zero-product property. For example, to show
    that the only numbers that square to $E$ are {`$\\pm \\sqrt{E}$`}, it
    suffices to find all solutions to $v^2 = E$. This is equivalent to:

    \[ v^2 - E = 0. \]

    This can be factored by finding numbers that sum to 0 and multiply to $-E$.
    Selecting {`$\\pm \\sqrt{E}$`} works. Therefore, this equation is equivalent
    to:

    {`
    \\[ (v-\\sqrt{E})(v+\\sqrt{E}) = 0, \\]
    `}

    and by the zero-product property, {`$\\pm \\sqrt{E}$`} are all the
    solutions.
    </PStyled>


    </Container>
    <Footer></Footer>
    </Styles>
  );
}
