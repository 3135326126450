import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import {PostJumbotron} from './../components/PostJumbotron';
import {Footer} from './../components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
`;

const ULStyled = styled.ul`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

export const QuadraticRelated = () => {
  return (
    <Styles>
    <PostJumbotron 
    date = 'Posted Oct 26, 2019; updated Dec 16, 2020'
    title = "Quadratic Method: Related Work"
    subTitle = ''>
    </PostJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
    It is surprising that something so retrospectively simple was not widely
    known, and so I am documenting the history of discovery around this
    particular approach. I still have not seen any previously-existing book or
    paper which states this type of method in a way that is suitable for
    first-time learners (avoiding advanced knowledge) and justifies all steps
    clearly and consistently. If you know of another publicly verifiable dated
    reference with the same method of solving general quadratic equations,
    please <a href="mailto:quadratic@poshenloh.com">contact me</a>, and I would
    be happy to learn more. I am recording the most similar approaches on this
    page.
    </PStyled>

    <h2>John Savage</h2>
    <PStyled>
    After sharing an earlier version of my article online, I was helpfully
    pointed to the nice article <a
    href="https://www.jstor.org/stable/27966090">Factoring Quadratics</a>, 
    by <a href="https://www.oame.on.ca/main/index.php?lang=en&code=archives&type=8&view=list&order=+">multiple</a> <a href="https://cemc1.uwaterloo.ca/~descartes/index.php?option=com_sobi2&sobi2Task=sobi2Details&catid=77&sobi2Id=110&Itemid=27">award-winning teacher John Savage</a> in <i>The Mathematics Teacher</i> Volume 82 (January 1989),
    pages 35–36, by <a href="https://twitter.com/benjamindickman">Benjamin
    Dickman</a>, who found this reference via <a
    href="https://scholar.google.com/">Google Scholar</a> keyword search.  This
    provided a coherent method of solving quadratic equations that overlapped in
    almost all calculations, with an apparent logical difference in assuming
    that every quadratic can be factored (requiring a significantly more
      advanced fact which would make it not suitable for first-time learners,
      unlike the method I proposed). It also has a pedagogical difference in
    choice of sign, factoring in the form $(x+p)(x+q)$ and negating at the end.
    It is a nice piece of work, and involves both core components: factoring and
    the Babylonian trick!
    </PStyled>
    <PStyled>
    Perhaps due to a friendly writing style which occasionally reverses the
    direction of logic, and leaves some logic up for interpretation, the
    published article as-written appears to use the additional (true but
      advanced) fact that every quadratic can be factored into two linear
    factors, which is beyond first-time learners, or it has some reversed
    directions of implication that are not technically correct. The directional
    reversals prevented the observation that an <a
    href="/quadraticcompsqr">extra assumption in Completing the Square</a> could
    be avoided. That said, they can be fixed, and Savage's contribution contains
    very nice insights.
    </PStyled>
    <PStyled>
    Since Savage's article is not easily viewable, I summarize it here. If
    anyone believes that I have misrepresented Savage's writing, please <a
    href="mailto:quadratic@poshenloh.com">contact me</a> and I will happily
    update this page as appropriate. The article is quite fun to read, and
    describes from the point of view of a teacher how Savage invented a method
    of factoring trinomials while teaching radicals and imaginary numbers to his
    tenth-grade enrichment class. The article opens with an anecdote where he
    asked his students to factor $x^2 + x + 1$, but they were unable to
    think of two numbers whose sum and product were both $1$.  Starting
    from a few illustrative numerical examples, his article builds up to
    and proposes the following complete method of solving quadratic
    equations, which he advocates for using throughout sections on
    factoring and quadratic equations, and for developing the quadratic
    formula:
    </PStyled>
    <OLStyled>

    <li>To factor $x^2 + Bx + C$ as $(x+p)(x+q)$, we want to find two numbers
    $p$ and $q$ with sum $B$ and product $C$.</li>

    <li>Since the sum of $p$ and $q$ is $B$, the average is $B/2$. The numbers
    $p$ and $q$ can then be represented by {`$\\frac{B}{2} \\pm u$`}.</li>

    <li>Since the product is $C$, then {`$\\frac{B^2}{4} - u^2 = C$`}.</li>
    
    <li>Since {`$\\frac{B^2}{4} - u^2 = C$`}, then {`$u^2 = \\frac{B^2}{4} -
        C$`}.</li>

    <li>Since the complete set of square roots of any number $D$ is {`$\\{\\pm
      \\sqrt{D}\\}$`} and {`$u^2 = \\frac{B^2}{4} - C$`}, then {`$u = \\pm
        \\sqrt{\\frac{B^2}{4} - C}$`}.
    </li>

    <li>Thus $p$ and $q$ are {`$\\frac{B}{2} \\pm \\sqrt{\\frac{B^2}{4} - C}$`}.</li>

    <li>By the zero-product property, the solutions are $-p$ and $-q$, which 
    are {`$-\\frac{B}{2} \\mp \\sqrt{\\frac{B^2}{4} - C}$`}.</li>
    </OLStyled>
    
    <PStyled>
    Perhaps due to the friendly writing style, the directions of implication in
    Steps 2–5 appear to be written in a way that presumes the definite existence
    of $p$ and $q$ as two numbers waiting to be found, and proceeds with forward
    deductions based upon them to find out what their values actually are
    (although Step 2 is once written with the reverse direction of logic and the
      direction is not explicitly specified thereafter). As will be detailed
    later, this is still mathematically sound.
    </PStyled>
    
    <PStyled>
    Specifically, successive rows of equations are written with single
    connective words such as "therefore", "thus", and "so", or with no
    connective words. These all represent forward deductions where the next
    statement is a logical consequence of all previous statements. The logical
    issues with approaches based on forward deduction are <a
    href="/quadraticlogicexist">detailed on this page</a>, which explains that
    those approaches require the initial knowledge that a factorization
    definitely exists. That matches the framing of the opening anecdote, which
    was asked in a way that presumed the existence of a factorization of $x^2 +
    x + 1$, and the article also says that students weren't sure the
    factorization {`$\\big(x + \\frac{1}{2} + \\frac{\\sqrt{3}}{2} i\\big)
      \\big(x + \\frac{1}{2} - \\frac{\\sqrt{3}}{2} i\\big)$`} produced by the
      method was correct until they multiplied the factors. Had the deductions
      been backward instead of forward, where previous lines were logical
      consequences of subsequent lines (as in the method I proposed), there
      would be no need to multiply the factors to check because the logic
      already went through.
    </PStyled>

    <PStyled>
    As detailed on <a href="/quadraticlogicexist">the above page</a>, forward
    deduction is still mathematically correct, as it is a consequence of the
    Fundamental Theorem of Algebra (or the quadratic formula) that every
    quadratic can be factorized into two linear factors. Indeed, perhaps that
    was already known to tenth-grade enrichment students. However, for
    first-time Algebra learners, it has not yet been proven that every quadratic
    has two roots (counting multiplicity), and so although forward deduction
    works, in order for this to become a self-contained elementary technique
    like the method I proposed, that existence assumption would need to be
    removed.
    </PStyled>

    <PStyled>
    It turns out to be relatively easy to edit Savage's published presentation
    to explicitly remove any reliance on the initial assumption that a
    factorization exists, by reversing the directions of logic in Steps 2–5.
    (For example, the logic in Step 3 would need to become "If there exists a
      $u$ such that {`$\\frac{B^2}{4} - u^2 = C$`}, then the product is $C$.")
      Reversing the logic would also achieve the benefit of removing the
      assumption in Step 5 that the complete set of square roots is known. <a
      href="/quadraticlogicexist#hardreverse">In general, a logical framework
      starting from assuming existence cannot naively be reversed</a> (another
        known derivation starts with $p+q = B \Rightarrow (p+q)^2 = B^2$ and
        then subtracts $4pq = 4C$ to get $(p-q)^2 = B^2 - 4C$, but $(p+q)^2 =
        B^2 \not \Rightarrow p+q = B$, and further examples are provided in the
        previous link). However, in this case, by fortune it just so happens to
    be easy to rewrite the published presentation to correctly reverse the
    directions of implication, by using language matching the method that I
    shared. A generous interpretation of the writing style could even choose to
    read connectives like "thus" to mean "thus it suffices to find" (actually
      reversing the implication), or "therefore" to mean "therefore
    equivalently" (observing both directions of implication), and to read
    sequences of equations to mean that they are equivalent instead of taking
    the customary meaning of successive forward deduction that occurs in the
    context of solving systems of equations by substitution (e.g., the sum and
      product system of equations here). It is worth mentioning that the logic
    turns out to be so conveniently correctable that anyone who is familiar with
    mathematical deduction could rewrite each connective phrase in Savage's
    paper to ensure the desired backward logical flow.
    </PStyled>

    <PStyled>
    There is a pedagogical difference in that Savage sought a factorization
    of $x^2 + Bx + C$ in the form $(x+p)(x+q)$, whereas my approach seeks a
    factorization in the form $(x-r)(x-s)$. Both can be used to teach this type
    of method effectively.  Regarding the choice of sign in which version to
    teach, I would still advocate for the version I originally proposed,
    which uses the factorization $(x-r)(x-s)$. I think it has a pedagogical
    advantage because it conceptually represents a clean and complete
    reduction via factoring from the problem of solving a standard
    quadratic equation to a sum-product problem which is in turn solved by
    the Babylonian method (with no negation required as a final step). It
    also provides a proof of Vi&egrave;te's relations between a quadratic's
    coefficients and the sum and product of its roots, therefore connecting
    key points in mathematical history.
    </PStyled>

    <h2>Completing the Square</h2>
    <PStyled>
    The arithmetic calculations in the method I proposed are almost exactly the
    same as those in the standard method of completing the square. That should
    be expected, because both methods result in the same quadratic formula.
    However, the logic is quite different, as the usual way of completing the
    square <a href="/quadraticcompsqr">actually requires an additional
    assumption</a>. That dedicated page contains a full comparison.
    </PStyled>

    <h2>Babylonians and Greeks</h2>
    <PStyled>
    The ancient Babylonians and Greeks already knew how to solve the
    problem of finding two numbers with given sum and product, using the
    same technique outlined in the corresponding step of Savage's article,
    and in my article. This is explicitly stated in Book I Problem 27 of <a
    href="https://archive.org/details/diophantusofalex00heatiala/page/140">Diophantus's
    Arithmetica</a>, from around 250 AD. A detailed discussion with some
    similar-looking calculations appears in <a
    href="https://www.amazon.com/History-Mathematics-Burton-Professor-Emeritus/dp/0073383155">The
    History of Mathematics: An Introduction</a> by David Burton.  Several
    readers have pointed out that the system of equations $x+y = A$ and $xy
    = B$ can be reduced to a quadratic of the form $x^2 - Ax + B = 0,$ and
    expressed concern that therefore the ancient Babylonians and Greeks
    already had the solution to general quadratic equations.
    </PStyled>

    <PStyled>
    They are indeed correct that the sum-and-product system can be reduced
    to a standard form quadratic, as that follows from using substitution
    to deduce that $y = A-x,$ and then plugging into the equation $xy = B$
    to deduce that $x(A-x) = B,$ which rearranges to $x^2 - Ax + B = 0.$
    This shows that any $(x, y)$ which satisfies the original system of
    equations $x+y=A$ and $xy=B$ must necessarily also satisfy $x^2 - Ax +
    B = 0.$ In other words, the original sum-and-product system of
    equations can be reduced to the standard form quadratic $x^2 - Ax + B =
    0.$
    </PStyled>
    <PStyled>
    However, the mathematical logic required is the opposite direction of
    reduction: in order to solve general quadratic equations, one needs the
    statement that a quadratic of the form $x^2 - Ax + B = 0$ can be
    reduced to the system of equations $x+y = A$ and $xy = B.$ 
    </PStyled>
    <PStyled>
    In both Burton's book and Diophantus's work, the ancient mathematicians
    did not deduce the opposite reduction in general ($x^2 - Ax + B = 0$
      with general values of $A$ and $B$ implies exactly two solutions
      always exist, namely the two numbers with sum $A$ and product $B$). A
    major reason was that ancient mathematicians did not work with negative
    numbers, not to mention irrational (or non-real complex) numbers.
    (Diophantus imposes the additional requirement that $(A/2)^2 > B$ is a
      positive perfect square in order to avoid non-real or irrational
      numbers.) Such a reduction needed to wait until complex numbers were
    invented, which was around 1500 AD.
    </PStyled>
    <PStyled>
    So, although pages 67-68 of Burton and Book I Problem 27 of Diophantus
    both show complete solutions of the system $x+y = A$ and $xy = B$ with
    the key substitution, neither explicitly observes or shows that you can
    reduce the general standard form quadratic to this system.
    </PStyled>
    <PStyled>
    This is particularly clear from page 70 of Burton, which separates
    quadratic equations into several forms based on the signs of their
    coefficients. The first two forms discussed on page 70 are $x^2 + Ax =
    B$ and $x^2 = Ax + B$ for positive $A$ and $B$, where their solution
    formulas are written without derivation. In contrast, on the same page
    the form $x^2 + B = Ax$ is said to have been "transformed by all sorts
    of ingenious devices" to the system $x+y = A$  and  $xy = B$, but no
    details are given of the transformation method, which is indeed
    difficult without the modern system of Algebra. The fact that Burton
    treats the first two forms ($x^2 + Ax = B$ and $x^2 = Ax + B$)
    differently from the third form ($x^2 + B = Ax$) means that there was
    no observation that every general standard form quadratic equation
    (possibly with non-positive coefficients) reduced to the
    sum-and-product system. Otherwise, there would have been no need to
    reduce only the third form and treat the first two forms differently.
    </PStyled>
    <PStyled>
    In conclusion, the sum-and-product system was solved in the same way by
    the ancient Babylonians and Greeks, but they did not know how to reduce
    general standard form quadratic equations to that system.
    </PStyled>

    <h2>Historical Mathematical Manuscripts</h2>

    <PStyled>
    While researching the novelty of this approach, I came across several
    ancient mathematical works. Thanks to the Internet, it is now possible
    for everyone to view and appreciate the creativity of early
    mathematicians.
    </PStyled>

    <ULStyled>

    <li><a
    href="https://archive.org/details/diophantusofalex00heatiala/page/n6">
    Arithmetica</a>, by Diophantus (circa 250)</li>

    <li><a
    href="https://archive.org/details/Brahmasphutasiddhanta_Vol_1/page/n3">
    Brahma-Sphuta Siddhanta</a>, by Brahmagupta (circa 628)</li>

    <li><a
    href="https://www.wilbourhall.org/pdfs/The_Algebra_of_Mohammed_Ben_Musa2.pdf">
    al-Kitab al-mukhtasar fi hisab al-jabr wal-muqabala </a>, by
    al-Khwarizmi (circa 825)</li>

    <li><a
    href="http://www.filosofia.unimi.it/cardano/testi/operaomnia/vol_4_s_4.pdf">Ars
    Magna</a>, by Cardano (1545)</li>

    <li><a href="https://gallica.bnf.fr/ark:/12148/bpt6k107597d.pdf">Opera
    Mathematica</a>, by Vi&egrave;te (1579)</li>

    <li><a
    href="https://archive.org/details/geschichtederel00tropgoog/page/n265">Geschichte
    der Elementar-Mathematik in systematischer Darstellung</a>, by Tropfke
    (1902—1903)</li>

    </ULStyled>

    </Container>
    <Footer></Footer>
    </Styles>
  );
}
