import React from "react";
import { Link } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import ImgHeader from "./assets/back_home.jpg";
import ImgPoFace from "./assets/poface.webp";
import ImgCollage from "./assets/pocollage.webp";
import ImgTour from "./assets/home_tour.webp";
import styled from "styled-components";

import {Footer} from "./components/Footer";
import {SocialMedia} from "./components/SocialMedia";

import ImgCBS from "./assets/logo_news_cbs.svg";
import ImgCNN from "./assets/logo_news_cnn.svg";
import ImgNewYorkTimes from "./assets/logo_news_nyt.svg";
import ImgWashingtonPost from "./assets/logo_news_wapo.svg";
import ImgWSJ from "./assets/logo_news_wsj.svg";


const Styles = styled.div`
  .jumbotron {
    background: url(${ImgHeader}) no-repeat;
    background-size: cover;
    color: white;
    height: 500px;
    position: relative;
    border-radius: 0 !important;
    margin-bottom: 0;
    padding-top: 60px;
  }

  .image-link {
    margin-top: 45px;
    margin-bottom: 45px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }

  .image-link img {
    height: 1.5rem;
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  .rowSection {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .invertedOffBlack {
    background-color: hsl(242, 54%, 5%);
    color: #ffffff;
  }

  .invertedBlurple {
    background-color: #5854cc;
    color: #ffffff;
  }

  .verticalAlignBottom {
    display: flex;
    height: 100%;
    align-items: end;
  }

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #5755c3;
    border-color: #5755c3;
    width: 200px;
    height: 50px;
    border-radius: 30px;
    font-weight: 500;
  }

  .buttonOutline, .buttonOutline:hover {
    border-color: white;
  }

  .liveNeon {
    font-size: 8rem;
    font-weight: 200;
    color: white;
    --neon-text-color: #5854cc;
    text-shadow:
    -0.2rem -0.2rem 1rem #fff,
    0.2rem 0.2rem 1rem #fff,
    0 0 2rem var(--neon-text-color),
    0 0 4rem var(--neon-text-color),
    0 0 6rem var(--neon-text-color),
    0 0 8rem var(--neon-text-color),
    0 0 10rem var(--neon-text-color);
  }
  @media only screen and (min-width: 600px) {
    .liveNeon {
      font-size: 10rem;
    }
  }
  @media only screen and (min-width: 1200px) {
    .liveNeon {
      font-size: 12rem;
    }
  }

  a {
    color: inherit;
    text-decoration: underline;
  }

  a:hover {
     color: inherit; 
     cursor:pointer;  
  }
`;

const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25;
  height: 0;
`;

const ImageLink = (props) => (
  <div className="image-link">
    <a href={props.href}>
      <img src={props.image} alt={props.alt}></img>
    </a>
  </div>
);


export const Home = () => {
  if (document.referrer.includes("cnn")) {
    window.location.href = "https://live.poshenloh.com";
    return;
  }

  return (
    <Styles>
      <Jumbotron>
        <Container style={{height: "100%"}}>
          <Row style={{height: "100%", paddingBottom: "2rem"}}>
            <Col xs={12} lg={7} className="verticalAlignBottom">
              <div>
                <h1>Po-Shen Loh: Magnetizing Mathematics</h1>
                <p>Connect with Prof. Loh</p>
                <SocialMedia></SocialMedia>
              </div>
            </Col>
            <Col className="d-none d-lg-block" xs={12} lg={5}>
              <img className="img-responsive" src = {ImgPoFace} alt="Po-Shen Loh face"></img>
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Row style={{backgroundColor: "#e3e3e8"}}>
        <ImageLink
          image={ImgWSJ}
          href="https://www.wsj.com/articles/chatgpt-ai-math-po-shen-loh-1e9f80dc?st=w2lhpkjyyq8o9mx&reflink=desktopwebshare_permalink"
          alt="Wall Street Journal logo link to helping humanity survive AI"
        />
        <ImageLink
          image={ImgCNN}
          href="https://cnn.com/world/professor-po-shen-loh-actors-classrooms-spc"
          alt="CNN logo to bringing together math stars and actors to revolutionnize online math classes"
        />
        <ImageLink 
          image={ImgCBS}
          href="https://www.cbsnews.com/news/americas-best-young-math-minds-compete-in-rio/"
          alt="CBS News logo link to Math Olympiad news"
        />
        <ImageLink
          image={ImgNewYorkTimes}
          href="https://www.nytimes.com/2020/02/05/science/quadratic-equations-algebra.html"
          alt="New York Times logo link to different way to solve quadratic equations"
        />
        <ImageLink
          image={ImgWashingtonPost}
          href="https://www.washingtonpost.com/news/answer-sheet/wp/2016/07/18/u-s-students-win-prestigious-international-math-olympiad-for-second-straight-year/"
          alt="Washington Post logo link to Math Olympiad news"
        />
      </Row>
      <Row className="rowSection">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6}>
              <h2>Innovator and Creator</h2>
              <p>Carnegie Mellon professor Po-Shen Loh is on a mission to
                help humanity thrive in the age of AI. He crosses over
                between disciplines from math to technology to performing
                arts, while perpetually on tour, speaking to and learning
                from audiences around the globe. He uses these experiences
                to innovate win-win (highly scalable) ecosystems to solve
                real world problems, ranging from reinventing learning to
                rethinking the paradigm for pandemic control.</p>
            </Col>
            <Col className="d-none d-sm-block" xs={12} lg={6}>
              <img src={ImgCollage} alt="Po-Shen Loh speaking" style={{maxWidth: "100%"}}></img>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection invertedOffBlack">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="live-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/3sgCAXWLdYk"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
            <Col xs={12} lg={6}>
              <h2>Revolutionizing Problem Solving Education</h2>
              <p>Prof. Loh created a new and deeply human ecosystem for
                teaching the core skill of how to solve
                previously-unfamiliar problems &mdash; essential in the age
                of AI. It is powered by a novel fusion of math stars and
                professional actors, teaching in a format that is as
                engaging as live-video streaming platforms like Twitch and
                Instagram Live.  Simultaneously, it develops a generation
                of kind and clever future leaders, to guide our world into
                a more thoughtful place.
              </p>
              <a href="https://live.poshenloh.com">
                <Button variant="primary">
                  Learn LIVE
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection invertedBlurple">
        <Container>
          <Row className="align-items-center">
            <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}}>
              <h2>Educator and Coach</h2>
              <p>Prof. Loh has been a math professor at Carnegie Mellon
                University since 2010, and served a decade-long term as the
                National Coach of the winning USA International
                Mathematical Olympiad team from 2013–2023. Through travel,
                collaboration, and intellectual curiosity, he uncovers math
                in unexpected places in our everyday lives.  His
                unconventional attitude informs his holistic approach to
                education and drives him to teach students of all levels,
                backgrounds, and ages.
              </p>
              <Link to="/about">
                <Button variant="primary" 
                  className="buttonOutline">
                  Read Bio
                </Button>
              </Link>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="educator-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/YqFqBTdsRYE"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="academic-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/6z1JwZbX4dQ"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
            <Col xs={12} lg={6}>
              <h2>Academic and Researcher</h2>
              <p>Prof. Loh's academic career focuses on original discovery
                in the areas of combinatorics, probability theory, and
                computer science. He has published numerous papers in
                academic journals and was recognized by the 2019 United
                States Presidential Early Career Award for Scientists and
                Engineers. 
              </p>
              <a href="https://www.math.cmu.edu/~ploh/cmu.shtml">
                <Button variant="primary">
                  Visit CMU Page
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection invertedBlurple">
        <Container>
          <Row className="align-items-center">
            <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}}>
              <h2>Pandemic Control</h2>
              <p>Prof. Loh pioneered the <a
                href="https://arxiv.org/abs/2010.03806">network radar
                paradigm</a> for pandemic control, which is fundamentally
                different from the strategies used by all other smartphone
                pandemic apps. The NOVID app was the first to empower the
                app user to directly avoid infection themselves. In
                contrast, all other apps primarily aimed to prevent the app
                user from infecting other people after the app user was
                already suspected of infection. This is a new approach for
                disease control which is driven by self-interest. This
                invention ended up making <a
                  href="https://www.straitstimes.com/tech/tech-news/us-prof-keen-to-work-with-spore-on-app-that-warns-users-before-they-are-exposed-to">national
                  press</a> in <a
                    href="https://www.abc.net.au/news/2022-01-26/omicron-spread-what-is-the-future-of-contact-tracing/100770948">several
                    countries</a>.
              </p>
              <a href="https://www.novid.org">
                <Button variant="primary" className ="buttonOutline">
                  Visit NOVID
                </Button>
              </a>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="novid-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/EIU-6FvwikQ"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="daily-challenge-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/NttsYtPXsIk"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
            <Col xs={12} lg={6}>
              <h2>Daily Challenge</h2>
              <p>Before developing his live video teaching platform, Prof.
                Loh created an original curriculum with a fresh approach to
                teaching advanced creative mathematical thinking to middle
                school students through carefully produced videos. The
                Daily Challenge is a fun and systematic tour of middle
                school competition math.</p>
              <a href="https://daily.poshenloh.com">
                <Button variant="primary" className ="buttonOutline">
                  Learn More
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection invertedBlurple">
        <Container>
          <Row className="align-items-center">
            <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}}>
              <h2>Multiplying Voices</h2>
              <p>Everyone is unique. To broaden the variety of styles of
                math and science lessons freely available on the Internet,
                Prof. Loh founded the free website expii.com. Today, it
                defines many math and science terms, providing multiple
                styles of explanations for each concept.
              </p>
              <a href="https://www.expii.com">
                <Button variant="primary" className ="buttonOutline">
                  Visit expii.com
                </Button>
              </a>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="expii-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/p30lPVyzva8"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="quadratic-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/ZBalWWHYFQc"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
            <Col xs={12} lg={6}>
              <h2>Quadratic Equations</h2>
              <p>While trying to teach elementary school students how to
                solve quadratics, Prof. Loh found a simpler and logically
                sound way to teach how to solve them. It has not been found
                in any previously published textbook.
              </p>
              <Link to="/quadratic">
                <Button variant="primary" className ="buttonOutline">
                  Learn Quadratics
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection invertedBlurple">
        <Container>
          <Row>
            <Col xs={{span: 12, order: 2}} lg={{span: 6, order: 1}}>
              <h2>Moderate Zoom Chat</h2>
              <p>While teaching online, Prof. Loh observed that big Zoom
                meetings (especially classrooms) were disengaged. He
                designed and developed a free system for anyone to turn
                their online lectures into lively interactions. The key was
                to create the first easy-to-use system that lets you
                moderate Zoom chat, and delete messages.
              </p>
              <a href="https://zoom.poshenloh.com">
                <Button variant="primary" className ="buttonOutline">
                  Make Zoom Lively
                </Button>
              </a>
            </Col>
            <Col xs={{span: 12, order: 1}} lg={{span: 6, order: 2}} className="mb-5 mb-lg-0">
              <Video>
                <iframe
                  title="zoom-chat-iframe"
                  style={{position: "absolute", top: 0, 
                    left: 0, width: "100%", height: "100%"}}
                  src="https://www.youtube.com/embed/Pv5m1ktfiMU"
                  frameBorder="0"
                  allowFullScreen
                />
              </Video>
            </Col>
          </Row>
        </Container>
      </Row>
      <Row className="rowSection">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} lg={6} className="mb-5 mb-lg-0">
              <img src={ImgTour} alt="Po-Shen Loh on tour" style={{maxWidth: "100%"}}></img>
            </Col>
            <Col xs={12} lg={6}>
              <h2>Speaking Tour</h2>
              <p>
                In Summer 2021, Prof. Loh conducted the largest outdoor
                solo math tour in history, <Link
                  to="/tour2021">visiting 40 cities over 7 weeks</Link>,
                holding free events in public parks.  He is continuing this
                tradition, and may soon be speaking in a city near you.
              </p>
              <Link to="/tour">
                <Button variant="primary" className ="buttonOutline">
                  Tour Schedule
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Row>
      <Footer/>
    </Styles>
  );
}
