import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import {PostJumbotron} from './../components/PostJumbotron';
import {Footer} from './../components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const Video = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25;
    height: 0;
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const ULStyled = styled.ul`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;

export const Quadratic = () => {
  return (
    <Styles>
    <PostJumbotron 
    date = 'Posted Dec 5, 2019; updated Dec 29, 2019'
    title = 'A Different Way to Solve Quadratic Equations'
    subTitle = ''>
    </PostJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <Video>
    <iframe title="myFrame" style={{position: "absolute", top: 0, left: 0, width: "100%", height: "100%"}} src={`https://www.youtube.com/embed/videoseries?list=PLqv4sKOD1bsUoSs-SbzlA2BE1tML4A33u`} frameBorder="0" allowFullScreen="allowFullScreen" />
    </Video>

    <PStyled>
    I've recently been thinking about how to explain school math concepts in
    more thoughtful and interesting ways, while creating my <i><a
    href="https://daily.poshenloh.com">Daily Challenge</a></i> lessons. One
    night in September, while brainstorming different ways to think about the
    quadratic formula, I came up with a simple way to solve quadratic equations
    that I had never seen before. I was very surprised, as this method was
    easier to understand than what is typically written in textbooks. Adding
    this technique as a standard method would directly improve the learning
    experience for anyone trying to understand this topic, which is part of the
    regular mathematical curriculum everywhere in the world.
    </PStyled>
    <PStyled>
    I <a href="https://arxiv.org/abs/1910.06709">publicly shared a formal
    article</a>, while continuing to investigate mathematical history around
    quadratic equations. I found that the individual steps of this method had
    been separately discovered by ancient mathematicians, and some deep digging
    unearthed modern teachers who were on the same track. In retrospect, the
    combination of these steps is something that anyone could have come up with,
    which makes it more surprising that this method is not commonly known.
    However, I have not seen any previously-existing book or paper which states
    the same pedagogical method and justifies all steps consistently, and so I
    chose to share it to provide a safely referenceable version.
    </PStyled> 

    <h2>More Information</h2>

    <ULStyled>
    <li><a href="/quadraticdetail">Detailed explanation of method</a> (most up-to-date version of method)</li>

    <li><a href="/quadraticrelated">Related work</a></li>

    <li><a href="https://arxiv.org/abs/1910.06709">Formal article</a></li>
    </ULStyled>


    </Container>
    <Footer></Footer>
    </Styles>
  );
}
