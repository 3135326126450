import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';
import ImgTourMap from './assets/tour-map-2022.jpg';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;


export const Tour2022 = () => {
  return (
    <Styles>
    <CustomJumbotron bgimage={ImgHeader}>2022 Tour</CustomJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
      This page is preserved for reference. To attend a future event, go to
      the <Link to="/tour">current tour page</Link>.
    </PStyled>

    <h2>
      2022 Tour Stops
    </h2>

    <img src={ImgTourMap} alt="2022 Tour Stop Map" style={{maxWidth: "100%"}}></img>

    <h2>
    Summer 2022 Tour
    </h2>

    <PStyled>
      In the summer of 2021, Prof. Loh conducted the largest
      outdoor solo math tour in human history, <Link
        to="/tour2021">visiting 40 cities in 7 weeks</Link>, holding
      events in public parks. It was so successful that he continued
      the tour in May 2022 with higher intensity.
      These fun events inspired students to see math from a
      different angle and to embrace challenge, while also sharing
      educational strategies with parents.
    </PStyled>

    <OLStyled>
      <li><a href="https://www.eventbrite.com/e/310772116767">Houston, TX (Sugar Land): Sat Apr 30 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/315632083057">Houston, TX (Hermann Park): Sat Apr 30 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/311438289307">Houston, TX (The Woodlands): Sat Apr 30 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/304256658857">Dallas, TX (Plano): Sun May 1 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/311455671297">Dallas, TX (Plano): Sun May 1 at 12:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/311833501397">Dallas, TX (Plano): Sun May 1 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321720824647">Austin, TX: Mon May 2 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321736541657">Middlesex County, NJ: Wed May 4 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/305770326277">West Windsor, NJ: Thu May 5 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321805257187">Philadelphia, PA: Fri May 6 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321831355247">Montgomery County, MD: Sat May 7 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/321967392137">Montgomery County, MD: Sat May 7 at 12:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/311444196977">Fairfax County, VA: Sat May 7 at 4:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/311453173827">Fairfax County, VA: Sat May 7 at 6:30pm</a></li>
      <li><a href="https://www.mathcounts.org/programs/2022-raytheon-technologies-mathcounts-national-competition">National MATHCOUNTS Competition</a> (Washington, DC): Mon May 8, visiting, but no public talk</li>
      <li><a href="https://www.eventbrite.com/e/321971564617">Raleigh, NC: Tue May 10 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321974102207">Charlotte, NC: Wed May 11 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322740975947">Atlanta, GA: Thu May 12 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321448590387">Boston, MA (Brookline): Fri May 13 at 6:00pm</a></li>
      <li><a href="https://www.lhsmath.org/LMT/Home">Lexington Math Tournament: Sat May 14 at 9:15am</a></li>
      <li><a href="https://www.eventbrite.com/e/330722238117">Weston, MA: Sat May 14 at 1:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321432732957">Boston, MA (Reading): Sat May 14 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/339303906117">New Haven, CT: Sun May 15 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/323308453287">Queens, NY (Bayside / Flushing): Sun May 15 at 5:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/335401022487">Ridgewood, NJ: Mon May 16 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/331979669127">Westchester, NY: Tue May 17 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321814896017">St. Louis, MO: Wed May 18 at 6:30pm</a></li>
        <li><a href="https://www.eventbrite.com/e/324349908307">Chicago, IL (Naperville): Thu May 19 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321978465257">San Francisco, CA (Saratoga): Fri May 20 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/324315655857">San Francisco, CA (Menlo Park): Sat May 21 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/322763352877">San Francisco, CA (Cupertino): Sat May 21 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322775118067">San Francisco, CA (Cupertino): Sat May 21 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322928887997">San Francisco, CA (Pleasanton): Sun May 22 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/337407102727">San Francisco, CA (Pleasanton): Sun May 22 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322781065857">Los Angeles, CA: Mon May 23 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/330210367097">Salt Lake City, UT (Sandy): Tue May 24 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/315806946077">Denver, CO: Wed May 25 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322942839727">Portland, OR: Thu May 26 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/334735100697">Bellevue, WA: Fri May 27 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/329195120467">Vancouver, Canada: Sat May 28 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/342131704137">Vancouver, Canada: Sat May 28 at 1:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/348260345067">Vancouver, Canada: Sat May 29 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/348268369067">Vancouver, Canada: Sat May 29 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/321986449137">Seattle, WA: Mon May 30 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/321989267567">Seattle, WA: Mon May 30 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/337509840017">Toronto, Canada: Tue May 31 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/323322244537">Minneapolis, MN: Wed Jun 1 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/322948606977">Detroit, MI: Thu Jun 2 at 7:00pm</a></li>
      <li><a href="https://www.arml.com">American Regions Math League Competition</a> (Penn State University): Fri Jun 3 at 8:00pm in Thomas Hall 101</li>
      <li><a href="https://www.eventbrite.com/e/323386928007">State College, PA: Sat Jun 4 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388709258857">Cleveland, OH: Thu Aug 18 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388732237587">Columbus, OH: Fri Aug 19 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/395050906897">Cincinnati, OH: Sat Aug 20 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388738757087">Indianapolis, IN: Sun Aug 21 at 3:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/392437179167">Louisville, KY: Mon Aug 22 at 6:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388744373887">Nashville, TN: Tue Aug 23 at 5:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388752317647">Memphis, TN: Wed Aug 24 at 6:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/391822169657">Birmingham, AL: Thu Aug 25 at 7:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/390573896037">Atlanta, GA (Marietta): Fri Aug 26 at 6:30pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388759529217">Atlanta, GA (Alpharetta): Sat Aug 27 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/388766740787">Atlanta, GA (Brookhaven): Sat Aug 27 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388778546097">Fairfax, VA: Sun Aug 28 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/388784483857">Fairfax, VA: Sun Aug 28 at 1:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/388787091657">Montgomery County, MD: Sun Aug 28 at 4:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/393148827727">Ellicott City, MD: Mon Aug 29 at 6:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/396769627637">Madison, WI: Sat Sep 3 at 1:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/395939253967">Milwaukee, WI: Sun Sep 4 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/402685141097">Vernon Hills, IL: Sun Sep 4 at 4:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/402668370937">South Bend, IN: Mon Sep 5 at 3:30pm</a></li>
    </OLStyled>

    <h2>
    Academic Year 2022–2023 Tour
    </h2>

    <PStyled>
      To work around his weekday teaching schedule at Carnegie Mellon
      University, Prof. Loh switched to a weekend schedule, and continued
      his speaking tour through Fall 2022 and Spring 2023.
    </PStyled>

    <OLStyled>
      <li><a href="https://www.eventbrite.com/e/414972422687">Vancouver, Canada: Sat Sep 17 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/415003646077">Vancouver, Canada: Sat Sep 17 at 4:00pm</a></li>
      <li>New York, NY (Stuyvesant High School): Fri Sep 23 at 8:00am</li>
      <li>Howard County, MD: Sun Oct 2 at 10:00am</li>
      <li>Andover, MA: Sat Oct 8, for <a href="https://andovermathopen.com/">Math Open at Andover</a></li>
      <li>Boston, MA: Sun Oct 9, for <a href="https://mathprize.atfoundation.org/">Math Prize for Girls</a>, but no public talk</li>
      <li><a href="https://www.nymathcircle.org/registration?c=F22SGUESTPO1">New York, NY (Columbia University): Sat Oct 15 at 10:00am</a>, at New York Math Circle</li>
      <li><a href="https://www.nymathcircle.org/registration?c=F22SGUESTPO">New York, NY (Columbia University): Sat Oct 15 at 2:00pm</a>, at New York Math Circle</li>
      <li><a href="https://holbrook.bcamathteam.org/">Bergen County, NJ (Bergen County Academies): Sun Oct 16 at 10:45am</a>, for parents at Joe Holbrook Memorial Math Competition</li>
      <li><a href="https://holbrook.bcamathteam.org/">Bergen County, NJ (Bergen County Academies): Sun Oct 16 at 1:00pm</a>, for students and parents at Joe Holbrook Memorial Math Competition</li>
      <li>Edison, NJ (Wardlaw + Hartridge School): Mon Oct 17</li>
      <li>New York, NY (Bronx High School of Science): Wed Oct 19</li>
      <li><a href="https://www.eventbrite.com/e/440919932387">Irvine, CA (University High School): Thu Oct 20 at 7:00pm</a></li>
      <li><a href="https://mathleague.org/mscontest.php?event=9806">San Jose, CA (Mathleague.org contest): Sat Oct 29</a></li>
      <li><a href="https://www.ocf.berkeley.edu/~bmt/">Berkeley, CA: Sat Nov 5 in morning</a>, parent/coach talk for Berkeley Math Tournament</li>
      <li><a href="https://www.eventbrite.com/e/451770195817">San Francisco, CA (Fremont): Sat Nov 5 at 2pm</a>, for middle school families</li>
      <li><a href="https://www.eventbrite.com/e/452074837007">San Francisco, CA (Fremont): Sat Nov 5 at 4:30pm</a>, for high school families about college admission</li>
      <li><a href="https://www.hmmt.org/">Boston, MA: Sat Nov 12 in morning</a>, for Harvard-MIT Math Tournament</li>
      <li><a href="https://sites.google.com/view/m-snake-competition/home">Boston, MA (Newton): Sat Nov 12 at 2:30pm</a>, for Newton local middle school math contest</li>
      <li><a href="https://www.eventbrite.com/e/450876974167">Oklahoma City, OK: Sat Nov 19 at 10:00am</a></li>
      <li><a href="https://www.eventbrite.com/e/461824568717">Tulsa, OK: Sat Nov 19 at 3:45pm</a></li>
      <li><a href="https://www.eventbrite.com/e/465726298897">Fayetteville, AR: Sun Nov 20 at 2:00pm</a></li>
      <li><a href="https://www.eventbrite.com/e/468514147427">Houston, TX (Sugar Land): Tue Nov 23 at 10:30am</a></li>
      <li><a href="https://www.eventbrite.com/e/471257011407">Houston, TX (Sugar Land): Tue Nov 23 at 3:00pm</a></li>
      <li><a href="https://tjvmt.com/tjimo">Fairfax, VA (Thomas Jefferson High School of Science and Technology) at 9:00am</a></li>
      <li><a href="https://mathleague.org/mscontest.php?event=9801">Atlanta, GA (Mathleague.org contest): Sat Dec 10 at 4:00pm</a></li>
      <li><a href="https://www.ahschool.com/poshen-loh">Miami, FL (American Heritage School Math Competition): Sat Dec 17</a></li>
      <li>Hong Kong: Tue Jan 3 and Wed Jan 4</li>
      <li>Manila, Philippines (Ateneo de Manila University): Sat Jan 6</li>
      <li>Jeddah, Saudi Arabia (King Abdullah University of Science and Technology): Jan 12</li>
      <li><a href="https://www.chmmc.org/">Los Angeles, CA (California Institute of Technology): Sat Jan 21 at 1:00pm</a></li>
      <li>Miami, FL (Florida Association of Mu Alpha Theta statewide competition)</li>
      <li>Phoenix, AZ: Sat Feb 11</li>
      <li><a href="https://www.hmmt.org/">Boston, MA (Massachusetts Institute of Technology)</a></li>
      <li><a href="https://mnmathleague.org/">Minneapolis, MN (High School Math League State Tournament): Mon Mar 13</a></li>
      <li><a href="https://cmimc.math.cmu.edu/">Pittsburgh, PA (Carnegie Mellon Informatics and Mathematics Competition): Sat Mar 25</a></li>
    </OLStyled>

    <h2>Stay Notified</h2>

    <PStyled>
      To stay notified about future tour stops, please subscribe to our <a
      href="https://eepurl.com/gExYzT">email newsletter</a> which sends
      timely information about math education and contest deadlines, every
      two weeks. If your language preference is Chinese, please follow the
      WeChat Public Account: 罗博深数学.
    </PStyled>

    <PStyled>
      To follow future online live streams, please subscribe to our <a
      href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
        Challenge with Po-Shen Loh YouTube Channel</a>.
    </PStyled>

    <h2>Learn Math</h2>

    <PStyled>
      To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
    </PStyled>


    </Container>
    <Footer></Footer>
    </Styles>
  );
}
