import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import {SocialMedia} from './SocialMedia';

import styled from 'styled-components';


const Styles = styled.div`
  h3, h5, h6 {
    color: white;
    font-weight: bold;
  }

  a {
    color: #a3a3a9 !important;
    text-decoration: none !important;
    font-size: 1.2rem;
    -o-transition:color .2s ease-out, background 1s ease-in;
    -ms-transition:color .2s ease-out, background 1s ease-in;
    -moz-transition:color .2s ease-out, background 1s ease-in;
    -webkit-transition:color .2s ease-out, background 1s ease-in;
    transition:color .2s ease-out, background 1s ease-in;
  }

  a:hover {
    color: #5755c3 !important;
  }
`;

const Featured = styled.div`
    background-color: #1f2126;
`;

const CustomLink = (props) => (
  <div style = {{marginTop: 15}}>
    <a href = {props.link}>{props.children}</a>
  </div>
)


export const Footer = (props) => {
  return (
    <Styles>
      <Featured>
        <Container style = {{paddingTop: 70, paddingBottom: 30}}>
          <Row>
            <Col md={4} style = {{paddingLeft: '7%', marginTop: 30, paddingRight: 0}}>
              <h3 style = {{marginBottom: 30}}>PO-SHEN LOH</h3>
              <SocialMedia></SocialMedia>

              <h5 style = {{marginTop: 25, marginBottom: 20}}>Other languages</h5>
              <div style = {{marginTop: 5, marginBottom: 5, color: "white"}}>
                <a href="https://m.thepaper.cn/newsDetail_forward_22665518">罗博深</a>
              </div>
              <div style = {{marginTop: 5, marginBottom: 5, color: "white"}} dir="rtl">
                <a href="https://www.themarker.com/weekend/2023-06-23/ty-article-magazine/.premium/00000188-de28-de38-a7ea-deedcc320000">פו-שן לו</a></div>
            </Col>
            <Col md = {4} style = {{paddingLeft: '7%', marginTop: 30}}>
              <h5 style = {{marginBottom: 25}}>Further Information</h5>
              <CustomLink link = "/about">About</CustomLink>
              <CustomLink link = "/news">News</CustomLink>
              <CustomLink link = "/tour">Tour</CustomLink>
              <CustomLink link = "/contact">Contact</CustomLink>
            </Col>
            <Col md = {4} style = {{paddingLeft: '7%', marginTop: 30}}>
              <h5 style = {{marginBottom: 25}}>Innovations</h5>
              <CustomLink link = "https://live.poshenloh.com">LIVE Math Courses</CustomLink>
              <CustomLink link = "https://www.novid.org">NOVID</CustomLink>
              <CustomLink link = "https://www.expii.com">Expii</CustomLink>
              <CustomLink link = "https://zoom.poshenloh.com">Moderate Zoom Chat</CustomLink>
              <CustomLink link = "/quadratic">Quadratics</CustomLink>
            </Col>
          </Row>
          <Row style = {{marginTop: 60}}>
            <Col md = {12} align = 'center'>
              <h6>2024 Expii, Inc.</h6>
            </Col>
          </Row>
        </Container>
      </Featured>
    </Styles>)
}
