import React from 'react';
import { Link } from "react-router-dom";
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import ImgHeader from './assets/back_tour.jpg';
import {CustomJumbotron} from './components/CustomJumbotron';
import {Footer} from './components/Footer';

const Styles = styled.div`
font-family: 'Montserrat', sans-serif;
.row {
  margin-left: 0px;
  margin-right: 0px;
  font-family: 'Montserrat', sans-serif;
}
.col {
  text-align: center;
}
`;

const PStyled = styled.p`
  font-size: 18px !important;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.4;
`;

const OLStyled = styled.ol`
  font-size: 18px !important;
  margin-bottom: 30px;
  line-height: 1.4;
  font-family: 'Montserrat', sans-serif;
`;


export const Tour2021 = () => {
  return (
    <Styles>
    <CustomJumbotron bgimage={ImgHeader}>2021 Tour</CustomJumbotron>
    <Container style = {{paddingTop: 70, paddingBottom: 100, paddingLeft: '10%', paddingRight: '5%'}}>

    <PStyled>
      This page is preserved for reference. To attend a future event, go to
      the <Link to="/tour">current tour page</Link>.
    </PStyled>

    <h2>
    Summer 2021 Tour
    </h2>

    <PStyled>
      When the COVID situation significantly improved in the USA in Summer
      2021, Prof. Loh embarked on the largest outdoor solo math tour in
      human history, visiting 40 cities in 7 weeks, giving nearly 60 free
      talks in park shelters around the country. Over 7,000 total people
      registered for these talks. He developed a 180-pound equipment kit
      which he could travel with solo, which could turn any open space into
      a lecture site: complete with a sound system, and visuals projected
      onto the audience's smartphones. These fun events inspired students
      to see math from a different angle and to embrace challenge, while
      also sharing educational strategies with parents.
    </PStyled>

    <OLStyled>
    <li><a href="https://www.eventbrite.com/e/math-in-games-strategy-and-invention-pittsburgh-pa-june-15th-2021-tickets-158312814891">Pittsburgh, PA: Tue Jun 15 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/math-in-games-strategy-and-invention-cleveland-oh-june-28-2021-tickets-159182367747">Cleveland, OH: Mon Jun 28 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160634928395">Columbus, OH: Tue Jun 29 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160237417429">Indianapolis, IN: Wed Jun 30 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160648003503">Cincinnati, OH: Thu Jul 1 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160656522985">Lafayette, IN: Fri Jul 2 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160067531295">Naperville, IL: Sat Jul 3 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/161788183813">Chicago Area, IL: Sat Jul 3 at 2:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/159915689131">Milwaukee, WI: Sun Jul 4 at 1:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160212262189">Madison, WI: Sun Jul 4 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160887064541">Minneapolis, MN: Wed Jul 7 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/161011799627">Iowa City, IA: Thu Jul 8 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/161016156659">Austin, TX: Fri Jul 9 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162087988537">Dallas — Fort Worth, TX: Sat Jul 10 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/161141680103">Dallas, TX (Plano): Sat Jul 10 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/161016963071">Dallas, TX (Plano): Sat Jul 10 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162779001375">Houston, TX: Sun Jul 11 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/162810981027">Houston, TX: Sun Jul 11 at 11:30am</a></li>
    <li><a href="https://www.eventbrite.com/e/161017460559">Houston, TX: Sun Jul 11 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/161017946011">St. Louis, MO: Mon Jul 12 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162101545085">Detroit, MI: Tue Jul 13 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163206449885">Boston, MA (Acton): Fri Jul 16 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162723286731">Boston, MA (Reading): Sat Jul 17 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162743872303">Boston, MA (Reading): Sat Jul 17 at 6:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163261907761">Greenwich, CT: Sun Jul 18 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/163149940865">Suffolk County, NY: Sun Jul 18 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163533893277">Nassau County, NY: Mon Jul 19 at 6:45pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163394307773">Middlesex County, NJ: Tue Jul 20 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163540501041">Westchester, NY: Wed Jul 21 at 5:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163216449795">Bergen County, NJ: Thu Jul 22 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163201986535">Berkeley Heights, NJ: Fri Jul 23 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/162972734837">West Windsor — Plainsboro, NJ: Sat Jul 24 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/163123184837">Philadelphia, PA: Sat Jul 24 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163421908327">Montgomery County, MD: Sun Jul 25 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/163422748841">Montgomery County, MD: Sun Jul 25 at 12:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/160227628149">Fairfax, VA: Sun Jul 25 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163376755273">Fairfax, VA: Sun Jul 25 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163910722383">Lexington, KY: Wed Jul 28 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/161943783215">Nashville, TN: Thu Jul 29 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164381488457">Charlotte, NC: Fri Jul 30 at 5:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164187419993">Charlotte, NC: Fri Jul 30 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164045326989">Raleigh, NC: Sat Jul 31 at 3:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164022659189">Atlanta, GA (Marietta): Sun Aug 1 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/164531473065">Atlanta, GA (Alpharetta): Sun Aug 1 at 4:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163576326195">Atlanta, GA (Alpharetta): Sun Aug 1 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164541314501">Birmingham, AL: Mon Aug 2 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165334055611">Memphis, TN: Tue Aug 3 at 6:15pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164195706779">Denver, CO: Thu Aug 5 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164984078821">Portland, OR: Fri Aug 6 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/163983640483">Portland, OR: Fri Aug 6 at 7:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165150105411">Seattle, WA (Bellevue): Sat Aug 7 at 9:30am</a></li>
    <li><a href="https://www.eventbrite.com/e/165177916595">Seattle, WA (Snohomish): Sat Aug 7 at 3:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165164418221">San Francisco, CA (Milpitas): Sun Aug 8 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/166198503197">San Francisco, CA (Milpitas): Sun Aug 8 at 2:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/164192320651">San Francisco, CA (San Ramon): Sun Aug 8 at 6:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165422165149">San Francisco, CA (Saratoga): Mon Aug 9 at 6:15pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165424538247">San Francisco, CA (Menlo Park): Tue Aug 10 at 6:15pm</a></li>
    <li><a href="https://www.eventbrite.com/e/166240615155">San Francisco, CA (Oakland): Wed Aug 11 at 6:15pm</a></li>
    <li><a href="https://www.eventbrite.com/e/165192207339">San Diego, CA: Sat Aug 14 at 10:00am</a></li>
    </OLStyled>

    <h2>
    Fall 2021 Tour
    </h2>

    <PStyled>
      To work around his weekday teaching schedule at Carnegie Mellon
      University, Prof. Loh switched to a weekend schedule, and continued
      his outdoor speaking tour through Fall 2021, until the rise of the
      Omicron variant.
    </PStyled>

    <OLStyled>
    <li><a href="https://www.eventbrite.com/e/172210745977">Kansas City, MO: Sat Sep 25 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/170480961144">Kansas City, MO: Sat Sep 25 at 5:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/173837561827">Morgantown, WV: Sun Oct 3 at 2:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/183686420027">Montgomery, NJ: Sat Oct 16 at 10:30am</a></li>
    <li><a href="https://www.eventbrite.com/e/177532844517">Somerset, NJ: Sat Oct 16 at 3:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/176624507657">Las Vegas, NV: Sat Oct 23 at 10:15am</a></li>
    <li><a href="https://www.eventbrite.com/e/174468238197">Oklahoma City, OK (Norman): Sat Oct 30 at 10:30am</a></li>
    <li><a href="https://www.eventbrite.com/e/174479190957">Oklahoma City, OK (Edmond): Sat Oct 30 at 3:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/187832621427">Phoenix, AZ: Sat Nov 6 at 1:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/187843012507">Phoenix, AZ: Sat Nov 6 at 3:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/192067688627">Tampa, FL: Sat Nov 13 at 10:00am</a></li>
    <li><a href="https://www.eventbrite.com/e/172224487077">Gainesville, FL: Sat Nov 13 at 4:00pm</a></li>
    <li><a href="https://www.eventbrite.com/e/191850308437">Irvine, CA: Sat Nov 20 at 10:30am</a></li>
    <li><a href="https://www.eventbrite.com/e/204617314897">Los Angeles, CA (Monterey Park): Sat Nov 20 at 2:30pm</a></li>
    <li><a href="https://www.eventbrite.com/e/212040277177">Los Angeles, CA (South Bay): Sun Nov 21 at 2:00pm</a></li>
    <li><a href="https://www.citybiz.co/article/177248/world-renowned-mathematics-professor-and-social-entrepreneur-to-speak-at-1-college-prep-school-in-the-u-s-in-math-competition/">Miami, FL (American Heritage School, Plantation): Sat Dec 11</a></li>
    </OLStyled>

    <h2>Stay Notified</h2>

    <PStyled>
      To stay notified about future tour stops, please subscribe to our <a
      href="https://eepurl.com/gExYzT">email newsletter</a> which sends
      timely information about math education and contest deadlines, every
      two weeks. If your language preference is Chinese, please follow the
      WeChat Public Account: 罗博深数学.
    </PStyled>

    <PStyled>
      To follow future online live streams, please subscribe to our <a
      href="https://www.youtube.com/channel/UCf78EJOm4wQ4xXwSS15PuxQ">Daily
        Challenge with Po-Shen Loh YouTube Channel</a>.
    </PStyled>

    <h2>Learn Math</h2>

    <PStyled>
      To learn math with Prof. Loh on a more regular basis, visit our <a href="https://live.poshenloh.com">classes</a>.
    </PStyled>



    </Container>
    <Footer></Footer>
    </Styles>
  );
}
